import * as moment from 'moment';
import { StorageService } from 'src/app/services/storage.service';

export interface IAudit {
    userId?: any;
    userName?: any;
    date?: any;
    activity?: any;
    ipAddress?:any;
    remark?:any;
    typeOfAudit?:any;
  }
  export class Audit implements IAudit {
    constructor(

    public userId?: any, 
    public userName?: any, 
    public date?: any, 
    public activity?: any, 
    public ipAddress?: any, 
    public remark?: any,
    public typeOfAudit?: any
    ) {
      let details: any = localStorage.getItem(`userDetails`);
     var data =   JSON.parse(details)
      this.userId = data.userId;
      this.userName = data.username;
      this.date =  moment(new Date()).format("YYYY-MM-DD");
    }
  }
  