import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  length: any;

  constructor() { }
  getItem(key: any) {
    return localStorage.getItem(`${key}`);
  }

  setItem(key: any, value: any) {
    localStorage.setItem(`${key}`, `${value}`);
  }

  removeItem(key: any) {
    localStorage.removeItem(`${key}`)
  }

  clear() {
    localStorage.clear();
  }

}
