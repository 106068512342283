import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UserRegistrationService } from 'src/app/administration/user-registration/user-registration.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-manage-form',
  templateUrl: './manage-form.component.html',
  styleUrls: ['./manage-form.component.less']
})
export class ManageFormComponent implements OnInit {

  @Input('fields') selectedcaseFormFieldInfo: any = [];
  @Output() onFormSubmitted = new EventEmitter();
  @Output() onclose = new EventEmitter();
  userRoles: any = []
  userData: any;
  selectedRoleId: any;
  isLoading=false;
  auditObject: any = {};
  isSignatureModal: boolean = false;
  constructor(private userService:UserRegistrationService,
    private commonService: CommonService,
    private storageService:StorageService,
    private authService: AuthService) { 
    this.userData = this.authService.getUserData();
    this.selectedRoleId = Number(this.userData.user_role_id);
  }

  ngOnInit(): void {
    this.getRoles();
  }

  getRoles(){
    this.userService.getRoles(1).subscribe((res: any) => {
      this.userRoles = res;
    }, (error: any) => {

    });
  }

  getCaseFormFields() {
    if(this.selectedRoleId){
      this.commonService.getCaseFormFields(this.selectedRoleId).subscribe((response) => {
        this.selectedcaseFormFieldInfo = response;
      })
    }
  }

  onModalChange(checked: any, index: any, type: any){
    if(checked){
      if(type == 1){
        this.selectedcaseFormFieldInfo[index].isRequired = 1;
        this.selectedcaseFormFieldInfo[index].isEditable = 1;
      }
      else{
        this.selectedcaseFormFieldInfo[index].isEditable = 1;
      }
    }
    else{
      if(type == 1){
        this.selectedcaseFormFieldInfo[index].isRequired= 0;
      }
      else{
        this.selectedcaseFormFieldInfo[index].isRequired = 0;
        this.selectedcaseFormFieldInfo[index].isEditable = 0;
      }
    }
  }

  updateFormControls(){
    this.isLoading=true;
    let body = {
      caseform: this.selectedcaseFormFieldInfo
    }
    this.commonService.updateCaseFormFields(this.selectedRoleId, body).subscribe((response) => {
      this.onFormSubmitted.emit(this.selectedcaseFormFieldInfo)
    })
  //  this.isSignatureModal =  true;
  }

  cancel(){
    this.onclose.emit(true)
  }
  getLoaderMessage(){
    if(this.storageService.getItem('loader')== "true"){
      return true;
    }
    this.isLoading = false;
    return false;
  }

  authenticateEvent(event: any){
    // this.isLoading=true;
    let body = {
      caseform: this.selectedcaseFormFieldInfo
    }
    this.commonService.updateCaseFormFields(this.selectedRoleId, body).subscribe((response) => {
      this.onFormSubmitted.emit(this.selectedcaseFormFieldInfo)
      this.auditObject = this.commonService.saveAuditTrialsObject(`changed the manage case form` , event.remarks, `Manage Case Form Audit Trails`);
    })
  }
  closeSignatureModal(event: any) {
    this.isSignatureModal = event;
  }
}
