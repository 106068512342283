import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { $ } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {
  GET_PREFERENCES_URL='admin/get/preferences';
  POST_PREFERENCES_URL='admin/post-preferences';
  GET_PREFERENCES_LOGOS_URL = `admin/get-preferences-logos`;
  UPDATE_PREFERENCES_URL='admin/update-preferences';
  DELETE_PREFERENCE_IMAGE_URL=`admin/delete-preferences`
  constructor(private http:HttpClient) { }

  savePreferencesForm(body:any) {
    return this.http.post(this.POST_PREFERENCES_URL,body)
  }
  getPreferencesLogos(){
    return this.http.get(this.GET_PREFERENCES_LOGOS_URL);
  }
  updatePreferencesForm(body:any) {
    return this.http.put(this.UPDATE_PREFERENCES_URL,body)
  }
  deletePreferenceLogos(id:any){
    return this.http.delete(`${this.DELETE_PREFERENCE_IMAGE_URL}/${id}`)

  }
}
