<div class="d-flex py-1">
    <!-- <h3 class="font-weight-normal" *ngIf="userStatus != 'APPROVED'">{{title}}</h3> -->
</div>
<div class="d-flex justify-content-end pb-3" style="margin-top: -15px;">
    <!-- <button nz-button nzType="primary" *HasAnyAuthority="['ASSIGN_CASE']"
        [routerLink]="['/case-management-system/cases/cases-assign']"><i nz-icon nzType="check"
            nzTheme="outline"></i><span>Assign Cases</span></button> -->
    <button nz-button nzType="primary" class="mx-1" [routerLink]="['/case-management-system/cases/casereceipt-form']"
        routerLinkActive="router-link-active" *HasAnyAuthority="['ADD_CASE']"><i nz-icon nzType="plus-circle"
            nzTheme="outline"></i><span>Add Case</span></button>
    <button nz-button nzType="primary" class="mx-1" [routerLink]="['/case-management-system/dashboard/sources/other']"
        *ngIf="isViewCases"><i nz-icon nzType="eye" nzTheme="outline"></i><span>View Cases</span></button>
    <button nz-button nzType="primary" class="mx-1" [routerLink]="['/medical-enquiry/faq']"
        ><i nz-icon nzType="eye" nzTheme="outline"></i><span>FAQ</span></button>

</div>