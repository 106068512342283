import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private NotificationService:NzNotificationService) { }

  alertMessage(type:any,icon:any,message:any) {
    return this.NotificationService.create(
      type,icon,message,
      { nzDuration: 2500 }
    );
  }
}
