<form nz-form [formGroup]="validateForm" nz-col nzSpan="24">
    <nz-layout class="no-bg">
        <nz-content nz-row>
            <nz-breadcrumb>
                <nz-breadcrumb-item></nz-breadcrumb-item>
            </nz-breadcrumb>
            <div nz-col [nzSpan]="12" class="pr-10">
                <nz-form-label [nzSpan]="24">UserID</nz-form-label>
                <nz-form-control [nzSpan]="24" nzErrorTip="Please enter UserID">
                    <nz-input-group>
                        <input type="text" nz-input formControlName="userid" readonly/>
                    </nz-input-group>
                </nz-form-control>
            </div><br>
            <div nz-col [nzSpan]="12"  class="pl-10">
                <nz-form-label [nzSpan]="24">Password</nz-form-label>
                <nz-form-control [nzSpan]="24" nzErrorTip="Please Enter your Password">
                    <nz-input-group>
                        <input type="password" nz-input formControlName="password" />
                    </nz-input-group>
                </nz-form-control>
            </div><br>
            <div nz-col [nzSpan]="24" class="py-15">
                <nz-form-label [nzSpan]="24" nzRequired>Remarks</nz-form-label>
                <nz-form-control [nzSpan]="24" nzErrorTip="Please enter remarks">
                    <nz-input-group >
                        <textarea  nz-input formControlName="remarks" rows="4"  [nzMaxCharacterCount]="100" placeholder="write your remarks" maxlength="100"></textarea>
                    </nz-input-group>
                </nz-form-control>
            </div>

            <span *ngIf="invalidPassword" style="color: red; margin-bottom: 10px;" class="text-center mt-1">Authentication Failed</span>
            <div nz-row nzJustify="end" nz-col nzSpan="24" class="mx-0 text-right">
                <div nz-col nzSpan="24" class="mx-0 text-right">
                    <button nz-button (click)="signatureCloseModal()" nzType="default"><i nz-icon nzType="delete"  nzTheme="outline"></i>Cancel</button>
                    <button nz-button  nzType="primary" class="p-0" (click)="Authenticate()">
                    <ng-container *ngIf="!isLoading" >
                        <i nz-icon nzType="send" class="pl-10" nzTheme="outline"></i> <span class="px-10">Authenticate</span> 
                    </ng-container>
                    <ng-container  *ngIf="isLoading">
                        <sb-button-loader class="w-100 " *ngIf="getLoaderMessage()" ></sb-button-loader>
                    </ng-container>
                    </button>
                    
                    
                </div>

            </div>

        </nz-content>

    </nz-layout>

</form>