<div class="px-8">
  
    <form nz-form [formGroup]="parameterForm" class="parameterForm py-20 text-left">
        <nz-alert nzType="error" class="pb-20" *ngIf="errorMessage !=''" nzMessage="{{errorMessage}}" nzDescription="Parameter Already Exists. Please Try with some other information" nzShowIcon> </nz-alert>
        <div nz-row [nzGutter]="24">
            <div nz-col class="gutter-row" [nzSpan]="8">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzJustify="left" nzRequired>Parameter Name</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Parameter Name">
                        <input nz-input formControlName="parameter_name" id="parameter_name" maxlength="255" autocomplete="off"/>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="8">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzJustify="left" nzRequired>Parameter Description</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Parameter Description">
                        <input nz-input formControlName="description" id="description" maxlength="255"  autocomplete="off"/>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="8">
                <nz-form-item>
                    <nz-form-label  [nzSpan]="24" nzJustify="left" nzRequired>Device</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Select Device">
                        <nz-radio-group formControlName="device" nz-col [nzSpan]="24" class="casevalidate">
                            <label nz-radio [nzValue]="'General'" [nzDisabled]="id != null">General</label>
                            <label nz-radio [nzValue]="'Instrumental'" [nzDisabled]="id != null">Instrumental</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <p *ngIf="id != null" class="d-flex mt-7"><strong class="pr-1">Note: </strong> <span>If you {{!isActivate ? 'deactivate' : 'activate'}} it might  effect to your already scheduled products</span></p>
        </div>
        <div *nzModalFooter>
            <div nz-row>
                <div nz-col nzSpan="8" class="text-left">
                    <button *ngIf="id && !isActivate" (click)="activateDeActivateDepartment(parameterForm, 'deactivate')"  nz-button class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>De-activate</button>
                    <button *ngIf="id && isActivate" (click)="activateDeActivateDepartment(parameterForm, 'activate')"  nz-button class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>Activate</button>
                </div>
                <div nz-col nzSpan="12" nzOffset="4" class="text-right">
                    <button (click)="cancel()" nz-button class="ml-10" nzType="default"><i nz-icon nzType="close"  nzTheme="outline"></i>Cancel</button>
                    <button *ngIf="!id" (click)="SubmitButton(parameterForm , 'save')" nz-button class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>Submit</button>
                    <button *ngIf="id" (click)="SubmitButton(parameterForm, 'update')" nz-button [disabled]="(parameterForm.value.parameter_name == originalValues?.parameter_name && parameterForm.value.description == originalValues?.description)" class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>Update</button>
                
                   
                </div>
                
            </div>
           
          </div>
    </form>
</div>

<nz-modal [(nzVisible)]="isSignatureModal" nzClassName="modal-no-footer" nzTitle="Electronic Signature"
(nzOnCancel)="isSignatureModal=false;">
<app-electronic-signature *ngIf="isSignatureModal" (AuthenticateEvent)="authenticateEvent($event)"  [auditObjectData]="auditObject" [deactivate]="isDeactivate"
    (SignaturepopupClose)="closeSignatureModal($event)"></app-electronic-signature>
</nz-modal>

<app-administration-table *ngIf="closeParameter == true" [closeParameter]="closeParameter"></app-administration-table>
