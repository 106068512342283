import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PolicySettingsService {
  GET_POLICY_SETTINGS_URL = 'admin/policy-settings';
  SAVE_POLICY_SETTINGS_URL = 'admin/save-policy-settings';
  UPDATE_POLICY_SETTINGS_URL = 'admin/update-policy-settings';
  interval: any;
  constructor(private http: HttpClient) { }

  getPolicySettings() {
    return this.http.get(this.GET_POLICY_SETTINGS_URL);
  }

  savePolicySettings(body:any) {
    return this.http.post(this.SAVE_POLICY_SETTINGS_URL,body)
  }

  updatePolicySettings(body:any) {
    return this.http.put(this.UPDATE_POLICY_SETTINGS_URL,body)
  }

}
