import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserRolesService {

  CREATE_ROLE='admin/user/createRole';
  GET_USER_ROLES_URL = 'admin/user/getroles';
  selectedTabIndex: any = 0;

  constructor(private httpClient:HttpClient) { }

  getUserRoles(status:any) {
    return this.httpClient.get(this.GET_USER_ROLES_URL + '?status=' + status);
  }
createRole(data:any){
  return this.httpClient.post(this.CREATE_ROLE,data)

}
}
