import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ConditionsService } from '../conditions.service';

@Component({
  selector: 'sb-conditions-form',
  templateUrl: './conditions-form.component.html',
  styleUrls: ['./conditions-form.component.less']
})
export class ConditionsFormComponent implements OnInit {
  conditionsForm: any = FormGroup;
  isSignatureModal = false;
  auditObject: any = {}
  id: any;
  isDeactivate: boolean = false;
  closeCondition: boolean = false;
  isActivate: boolean = false;
  conditionStatus : any;
  errorMessage: string = "";
  originalValues: any;

  constructor(private fb: FormBuilder, private conditionsService:ConditionsService, private router: Router, private messagService: AlertService,
    private readonly activedRoute: ActivatedRoute, private readonly modalservice: NzModalService, private readonly authservice: AuthService, private readonly commonservice : CommonService) {
    this.id = this.activedRoute.snapshot.params['conditionId'];
  }

  ngOnInit(): void {
    this.getconditionsForm();
    if (this.id) {
      this.getConditionsById();
    }
  }
  getConditionsById() {
    this.conditionsService.getConditionsById(this.id).subscribe((res: any) => {
      if(res.active == 0) {
        this.isActivate = true;
      }; 
      this.conditionStatus = res.active;
      this.originalValues = res;
      this.conditionsForm.patchValue(res);
    }, error => {
      console.log(error);
    });
  }
  getconditionsForm() {
    this.conditionsForm = this.fb.group({
      id: [''],
      condition_name: ['', [Validators.required]],
      description: ['', [Validators.required]]
    })
  }


  cancel() {
    // this.router.navigateByUrl('/administration/masters/department')
    this.closePopup();
  }

  SubmitButton(data: any, type?: any) {
    if(type == 'save') {
      this.postCondition(data,type)
    }
    else {
      this.updateCondition(data,type)
    }
  }


  postCondition(data: any, type: any) {
    for (const i in data.controls) {
      data.controls[i].markAsDirty();
      data.controls[i].updateValueAndValidity();
    }
    if (data.valid) {
      if (type == 'deactivate') {
        this.isDeactivate = true;
      } else {
        this.isDeactivate = false;
      }
      this.isSignatureModal = true;
    }
  }

  updateCondition(data: any, type: any) {
    for (const i in data.controls) {
      data.controls[i].markAsDirty();
      data.controls[i].updateValueAndValidity();
    }
    if (data.valid) {
      if (type == 'deactivate') {
        this.isDeactivate = true;
      } else {
        this.isDeactivate = false;
      }
      if(this.originalValues.condition_name!=this.conditionsForm.value.condition_name || this.originalValues.description!=this.conditionsForm.value.description) {
        this.isSignatureModal = true;
      } 
    }
  }
 
  authenticateEvent(event: any) {
    if (!this.id) {
      const conditionname = this.conditionsForm.controls.condition_name.value;
      this.conditionsService.postConditions(this.conditionsForm.value).subscribe((res: any) => {
        if(res.message == 'condition already exist') {
          this.errorMessage = res.message;
          setTimeout(() => {
            this.errorMessage = '';
          }, 3000);
        }
        else {
          this.auditObject = this.commonservice.saveAuditTrialsObject(` Created Condition "${conditionname}" with Condition Description "${this.conditionsForm.value.description}"`, event.remarks, `Condition Audit Trails`);
          this.messagService.alertMessage("success", "Success", "Condition Added Successfully");
          this.closePopup();
        }  
      }, error => {
        console.log('error');

      });
      this.isSignatureModal = false;
    } 
    else {
      if (this.isDeactivate) {
        this.deActivateCondition(event);
      } else {
        const conditionname = this.conditionsForm.controls.condition_name.value;
        let changedValue: any = [];
        let body = {
          values:this.conditionsForm.value,
          originalValues: this.originalValues 
        }
        if(this.conditionsForm.value!=this.originalValues) {
          if(this.originalValues.condition_name!=this.conditionsForm.value.condition_name) {
            changedValue.push(`Condition Name from '${this.originalValues.condition_name}' to '${this.conditionsForm.value.condition_name}'`)
          }
          if(this.originalValues.description!=this.conditionsForm.value.description) {
            changedValue.push(`Condition Description from '${this.originalValues.description}' to '${this.conditionsForm.value.description}'`)
          }
          this.conditionsService.updateCondition(this.conditionsForm.value).subscribe((res: any) => {
            if(res.message == 'Condition already exist') {
              this.errorMessage = res.message;
              setTimeout(() => {
                this.errorMessage = '';
              }, 3000);
            }
            else {
              this.auditObject = this.commonservice.saveAuditTrialsObject(`changed "${changedValue.toString()}"`, event.remarks, `Condition Audit Trails`);

              this.messagService.alertMessage("success", "Success", "Condition Updated Successfully");
              this.closePopup();
            }  
          }, error => {
            console.log('error');

          });
          this.isSignatureModal = false;
        }  
      }
    }
    setTimeout(() => {
      // this.router.navigateByUrl('/administration/masters/department');
 
    }, 4000);
  }

  deActivateCondition(event: any) {
    const conditionname = this.conditionsForm.controls.condition_name.value;
    let activateName: any;
    if(this.conditionStatus == 1) {
      activateName = 'DeActivated'
    }
    else if(this.conditionStatus  == 0) {
      activateName = 'Activated'
    }
    let body = {
      id:this.id,
      active:activateName == 'Activated' ? 1 : 0
    }
    this.conditionsService.updateCondition(body).subscribe((res: any) => {
      this.auditObject = this.commonservice.saveAuditTrialsObject(` ${activateName} The condition "${conditionname}" `, event.remarks, `Condition Audit Trails`);
      this.messagService.alertMessage("success", "Success", `Condition ${activateName} Successfully`);
      this.closePopup();

    }, error => {
      console.log('error');

    });
    this.isSignatureModal = false;
    setTimeout(() => {
      // this.router.navigateByUrl('/administration/masters/department');
 
    }, 4000);
  }
  closeSignatureModal(event: any) {
    this.isSignatureModal = event;
  }
  closePopup() {
    this.modalservice.closeAll();
    this.closeCondition = true;
    this.commonservice.getApisData(true);
  }

  activateDeActivateCondition(data: any, type: any) {
    for (const i in data.controls) {
      data.controls[i].markAsDirty();
      data.controls[i].updateValueAndValidity();
    }
    if (data.valid) {
      this.isDeactivate = true;
      this.isSignatureModal = true;
    }
  }
}
