import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable ,throwError} from 'rxjs';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../services/common.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { StorageService } from 'src/app/services/storage.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  userData:any
  constructor(private authenticationService: AuthService, private storageService:StorageService, private commonService: CommonService,private alertService: NzMessageService) { 
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let restUrl;
    
    if (request.url.includes(environment.baseUrl) || request.url.includes(environment.pythonbaseurl)) {
      restUrl = request.url;
    } else {
      if(request.url.includes('api.ipify.org')){
        restUrl =  request.url
      }
      else if(request.url.includes("healthauth/") || request.url.includes("generate-adr-xml")) {
        restUrl = environment.pythonbaseurl + request.url
      }
      else if (request.url.includes("health/reconsalition")){
        restUrl = environment.pythonbaseurl +request.url 
      }
      else{
        restUrl = environment.baseUrl + request.url
      }
    }
    if(!request.url.includes('api.ipify.org')){
    let data:any=this.storageService.getItem('userDetails')
    this.userData=JSON.parse(data)
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.storageService.getItem('token')}`,
        'csrf-token': `${this.storageService.getItem('csrfToken')}`,
      },
      url: restUrl
    },
    );
  }
    return next.handle(request).pipe(
      catchError((err) => {
        if(err.status === 0) {
          this.alertService.warning('Something went wrong. Please try again after some time', { nzDuration: 2000});
          err.error.message = 'Something went wrong. Please try again after some time';
          err.statusText = 'Something went wrong. Please try again after some time';
        }
        else if(err.status === 500) {
          this.alertService.warning('Something went wrong. Please try again after some time', { nzDuration: 2000});
          err.error.message = 'Something went wrong. Please try again after some time';
          err.statusText = 'Something went wrong. Please try again after some time';
        }
        else if(err.status == 401) {
          this.authenticationService.logout();
          this.alertService.warning('Something went wrong. Please try again after some time', { nzDuration: 2000});
          err.error.message = 'Something went wrong. Please try again after some time';
          err.statusText = 'Something went wrong. Please try again after some time';
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
