import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConditionsService {
  selectedTabIndex: any = 0;

  POST_CONDIITONS_URL = 'product/condition/create';
  UPDATE_CONDITIONS_URL = `product/condition/update`;
  GET_CONDITIONS_URL = `product/condition/list`
  GET_CONDITIONS_BY_ID = `product/condition`


  constructor(private httpClient: HttpClient) { }


  postConditions(body: any) {
    return this.httpClient.post(this.POST_CONDIITONS_URL, body)
  }
  updateCondition(body: any) {
    return this.httpClient.put(`${this.UPDATE_CONDITIONS_URL}`, body)
  }
  getConditionsList(activeStatus:any){
    return this.httpClient.get(`${this.GET_CONDITIONS_URL}?type=${activeStatus}`)
  }
  getConditionsById(id:any){
    return this.httpClient.get(`${this.GET_CONDITIONS_BY_ID}/${id}`)
  }
  
}
