import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PreferencesService } from 'src/app/administration/settings/preferences/preferences.service';
import { UserRegistrationService } from 'src/app/administration/user-registration/user-registration.service';
import { UserRolesService } from 'src/app/administration/user-roles/user-roles.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { PermissonsService } from 'src/app/core/services/permissions.service';
import { StorageService } from 'src/app/services/storage.service';
import { AlertService } from '../../services/alert.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.less']
})
export class SideNavComponent implements OnInit {
  isCollapsed = false;
  selectedMenuItem: any;
  selectedSubMenuItem: any;
  menuItemss: any = [];
  moduleNames: any = [];
  currentUser: any;
  moduleForm!: FormGroup;
  selectedModule: any;
  menuItems:any;
  logo : any ;
  logoLoading:boolean = false;
  logoValue: any;
  confirmModal?: NzModalRef;

  constructor(private authService: AuthService, private router: Router, public commonService: CommonService,
    private preferenceService: PreferencesService,
    private permissonsService: PermissonsService, private route: ActivatedRoute,
    private alertService: AlertService,
    public userService: UserRegistrationService,
    public userRoleService: UserRolesService, private storageService:StorageService) { 
    this.currentUser = this.authService.getUserData();
    router.events.subscribe((event: any) => {
      if(event instanceof NavigationEnd) {
        let URL = event.url;
        if (URL.includes('/masters/')) {
          this.selectedMenuItem = "Masters";
          if (URL.includes('/plant-master')) {
            this.selectedSubMenuItem = 'Plant Master'
          }
        else if (URL.includes('/department')) {
            this.selectedSubMenuItem = 'Departments'
          }
          else if (URL.includes('/parameters')){
            this.selectedSubMenuItem = 'Parameters'
          }else if (URL.includes('/conditions')){
            this.selectedSubMenuItem = 'Conditions'
          }
        }
        else if (URL.includes('/settings/')) {
          this.selectedMenuItem = "Settings";
          if (URL.includes('/company-information')) {
            this.selectedSubMenuItem = 'Company Information'
          }
          else if (URL.includes('/mail-templates')) {
            this.selectedSubMenuItem = 'Templates'
          }
          else if (URL.includes('/preferences')) {
            this.selectedSubMenuItem = 'Preferences'
          }
          else if (URL.includes('/email')) {
            this.selectedSubMenuItem = 'Email Settings'
          }
          else if (URL.includes('/adr-configurations')) {
            this.selectedSubMenuItem = 'ADR Configurations'
          }
          else if (URL.includes('/manage-form')) {
            this.selectedSubMenuItem = 'Manage Form Fields'
          }
          else if (URL.includes('/health-authority-configurations')) {
            this.selectedSubMenuItem = 'Health Authority Configurations'
          }
          else if (URL.includes('/timeline-settings')) {
            this.selectedSubMenuItem = 'Timeline Settings'
          }
        }
        else if (URL.includes('/admin/')) {
          this.selectedMenuItem = "Administration";
          if (URL.includes('/audit-trails')) {
            this.selectedSubMenuItem = 'Audit-Trails'
          }
          else if (URL.includes('/login-logout-history')) {
            this.selectedSubMenuItem = 'Login-Logout History'
          }
          else if (URL.includes('/password-request-user-unlock-list')) {
            this.selectedSubMenuItem = 'Password Request And User Account Unlock'
          }
          else if (URL.includes('/user-registration')) {
            this.selectedSubMenuItem = 'User-registration'
          }
          else if (URL.includes('/user-roles')) {
            this.selectedSubMenuItem = 'Roles'
          }
          else if (URL.includes('/policy-settings')) {
            this.selectedSubMenuItem = 'Policy Settings'
          }
        }
        else if (URL.includes('/literature-monitoring/')) {
          if (URL.includes('/dashboard')) {
            this.selectedMenuItem = 'Dashboard'
          }
          else if (URL.includes('/Reports')) {
            this.selectedMenuItem = 'Reports'
          }
        }  
        else{
          if (URL.includes('/dashboard')) {
            this.selectedMenuItem = 'Home'
          }
          else if (URL.includes('/casereceipt-form')) {
            this.selectedMenuItem = 'Add Case'
          }
          else if (URL.includes('/cases-assign')) {
            this.selectedMenuItem = 'Assign Cases'
          }
          else if (URL.includes('/reports')) {
            this.selectedMenuItem = 'Reports'
          }
          else if (URL.includes('/statistics')) {
            this.selectedMenuItem = 'Statistics'
          }
          else if (URL.includes('/documents-repo')) {
            this.selectedMenuItem = 'Documents-Repo'
          }
          else if (URL.includes('/faq')) {
            this.selectedMenuItem = 'FAQ'
          }
          else if (URL.includes('/product-dictionary')) {
            this.selectedMenuItem = 'Product-Dictionary'
          }
          if(URL.includes('/audit-trails')){
            this.selectedMenuItem = "Administration";
            this.selectedSubMenuItem = 'Audit-Trails'
          }
        }
      }
    });
  }

  ngOnInit(): void {
      this.logoLoading = this.router.url.includes("administration");
      if(this.logoLoading == true) {
        this.getPreferencesLogos();
      }
      else {
        if(this.storageService.getItem('preferencesLogo')!=null || undefined) {
          this.logo = this.storageService.getItem('preferencesLogo');
          this.logoValue = true;
        }
        else{
          this.logoValue = false;
        }
      }  
    this.buildModuleForm();
    this.permissionsOnConditions();
    this.ModulePermissions();
  }

  getPreferencesLogos(){
    this.preferenceService.getPreferencesLogos().subscribe((res: any) => {
      if(res.length>0) 
      {
        this.logo = res[0].logo;
        this.logoValue = true;
      }
      else{
        this.logoValue = false;
      }
    }, (error: any) => {
        console.log("Error ",error)
    })
  }


  permissionsOnConditions() {
    // if (this.storageService.getItem('moduleName') == 'Case Intake Module') {
    //   this.loadCaseManagemenItems();
    // } else if (this.storageService.getItem('moduleName') == 'Medical Enquiry Module') {
    //   this.loadMedicalEnquiryItems();
    // } else if (this.storageService.getItem('moduleName') == 'Administration') {
    //   this.loadAdministrationItems();
    // }
    // else if (this.storageService.getItem('moduleName') == 'Literature Monitoring') {
    //   this.loadLiteratureMonitoringItems();
    // }
    this.loadAdministrationItems();
  }

  buildModuleForm() {
    this.moduleForm = new FormGroup({
      moduleName: new FormControl()
    })
  }

  getMenuItemsByPermissions(menuItems: any){
    for (let i = 0; i < menuItems.length; i++) {
      if(menuItems[i].subMenuItems){
        let menu_Items = menuItems[i];
        let subMenu_Items: any = [];
        var itemsProcessed = 0;
        menu_Items.subMenuItems.forEach((element: any) => {
          itemsProcessed++;
          if(typeof element.permission == 'string'){
            if(this.permissonsService.hasAccess([String(element.permission)])){
              subMenu_Items.push(element);
            }
          }
          else{
            for (let j = 0; j < element.permission.length; j++) {
              if(this.permissonsService.hasAccess([String(element.permission[j])])){
                subMenu_Items.push(element);
                break;
              }
            }
          }
          if(itemsProcessed === menu_Items.subMenuItems.length) {
            if(subMenu_Items.length > 0){
              menu_Items.subMenuItems = subMenu_Items;
              this.menuItemss.push(menu_Items);
            }
          }
        });
      }
      else{
        if(typeof menuItems[i].permission == 'string'){
          if(this.permissonsService.hasAccess([String(menuItems[i].permission)])){
            this.menuItemss.push(menuItems[i]);
          }
        }
        else{
          for (let j = 0; j < menuItems[i].permission.length; j++) {
            if(this.permissonsService.hasAccess([String(menuItems[i].permission[j])])){
              this.menuItemss.push(menuItems[i]);
              break;
            }
          }
        }
      }
    }
  }

  loadCaseManagemenItems() {
    let menuItems = [
      {
        mainMenu: 'Home',
        route: '/case-management-system/dashboard/sources/phone',
        icon: 'home',
        permission: 'CASE_VIEW',
        select: 'true'
      },
      {
        mainMenu: 'Add Case',
        route: '/case-management-system/casereceipt-form',
        icon: 'plus-circle',
        permission: 'ADD_CASE',
      },
      {
        mainMenu: 'Assign Cases',
        route: '/case-management-system/cases-assign',
        icon: 'file-done',
        permission: 'ASSIGN_CASE'
      },
      {
        mainMenu: 'Reports',
        route: '/case-management-system/reports',
        icon: 'file',
        permission: 'REPORTS'
      },
      {
        mainMenu: 'Statistics',
        route: '/case-management-system/statistics',
        icon: 'pie-chart',
        permission: 'STATISTICS'
      }
    ];
    this.getMenuItemsByPermissions(menuItems);
  }

  loadMedicalEnquiryItems() {
    let menuItems = [];
    if(this.currentUser.recieveCaseStatus == "CASEINTAKE_INITIATED"){
      menuItems = [
        {
          mainMenu: 'Assign Cases',
          route: '/medical-enquiry/cases-assign',
          icon: 'file-done',
          permission: 'ASSIGN_CASE'
        },
        {
          mainMenu: 'FAQ',
          icon: 'question-circle',
          route: '/medical-enquiry/faq',
          permission: ['FAQ_INITIATION', 'FAQ_REVIEW', 'FAQ_APPROVE']
        },
        {
          mainMenu: 'Documents-Repo',
          route: '/medical-enquiry/documents-repo',
          icon: 'database',
          permission: ['DOCUMENT_INITIATION', 'DOCUMENT_REVIEW', 'DOCUMENT_APPROVE']
        },
        {
          mainMenu: 'Product Dictionary',
          icon: 'control',
          permission: true,
          subMenuItems: [
            {
              subMenuTitle: 'Products',
              icon: 'safety-certificate',
              route: '/medical-enquiry/product-dictionary',
              permission: 'PRODUCT_DICTIONARY',
  
            },
            {
              subMenuTitle: 'Masters',
              icon: 'cluster',
              route: '/medical-enquiry/product-dictionary-masters',
              permission: 'PRODUCT_DICTIONARY',
  
            }
          ]
        },
        {
          mainMenu: 'Reports',
          route: '/medical-enquiry/reports',
          icon: 'file',
          permission: 'REPORTS'
        },
        {
          mainMenu: 'Statistics',
          route: '/medical-enquiry/statistics',
          icon: 'pie-chart',
          permission: 'STATISTICS'
        }
      ];
    }
    else{
      menuItems = [
        {
          mainMenu: 'Home',
          icon: 'home',
          route: '/medical-enquiry/dashboard',
          permission: 'CASE_VIEW'
        },
        {
          mainMenu: 'Assign Cases',
          route: '/medical-enquiry/cases-assign',
          icon: 'file-done',
          permission: 'ASSIGN_CASE'
        },
        {
          mainMenu: 'FAQ',
          icon: 'question-circle',
          route: '/medical-enquiry/faq',
          permission: ['FAQ_INITIATION', 'FAQ_REVIEW', 'FAQ_APPROVE']
        },
        {
          mainMenu: 'Product Dictionary',
          icon: 'control',
          permission: true,
          subMenuItems: [
            {
              subMenuTitle: 'Products',
              icon: 'safety-certificate',
              route: '/medical-enquiry/product-dictionary',
              permission: 'PRODUCT_DICTIONARY',
  
            },
            {
              subMenuTitle: 'Masters',
              icon: 'cluster',
              route: '/medical-enquiry/product-dictionary-masters',
              permission: 'PRODUCT_DICTIONARY',
  
            }
          ]
        },
        {
          mainMenu: 'Statistics',
          route: '/medical-enquiry/statistics',
          icon: 'pie-chart',
          permission: 'CASE_VIEW'
        },
        {
          mainMenu: 'Documents-Repo',
          route: '/medical-enquiry/documents-repo',
          icon: 'database',
          permission: ['DOCUMENT_INITIATION', 'DOCUMENT_REVIEW', 'DOCUMENT_APPROVE']
        }
      ];
    }
    this.getMenuItemsByPermissions(menuItems);
  }

  loadAdministrationItems() {
    let menuItemss = [
      {
        mainMenu: 'Administration',
        icon: 'robot',
        permission: true,
        subMenuItems: [
          {
            subMenuTitle: 'Audit-Trails',
            route: '/administration/admin/audit-trails',
            icon: 'file-done',
            permission: 'AUDIT_TRAILS',
          },
          {
            subMenuTitle: 'Login-Logout History',
            icon: 'file-sync',
            route: '/administration/admin/login-logout-history',
            permission: 'LOGIN_LOGOUT_HISTORY'
          },
          {
            subMenuTitle: 'Password Request And User Account Unlock',
            icon: 'file-protect',
            route: '/administration/admin/password-request-user-unlock-list',
            permission: 'PASSWORD_REQUEST_AND_USER_UNLOCK'
          },
          // {
          //   subMenuTitle: 'Reports',
          //   icon:'file-text',
          //   route:'/administration/reports',
          //   permission:'CASE_VIEW',
          // },
          {
            subMenuTitle: 'User-registration',
            icon: 'user',
            route: '/administration/admin/user-registration',
            permission: 'USER_REGISTRATION',
          },
          {
            subMenuTitle: 'Roles',
            icon: 'team',
            route: '/administration/admin/user-roles',
            permission: 'ROLES',
          },
          {
            subMenuTitle: 'Policy Settings',
            icon: 'snippets',
            route: '/administration/admin/policy-settings',
            permission: 'POLICY_SETTINGS',
          },
         
        ]
      },
      {
        mainMenu: 'Masters',
        icon: 'control',
        permission: true,
        subMenuItems: [
          {
            subMenuTitle: 'Plant Master',
            icon: 'safety-certificate',
            route: '/administration/masters/plant-master',
            permission: 'PLANT_MASTER',

          },
          {
            subMenuTitle: 'Departments',
            icon: 'cluster',
            route: '/administration/masters/department',
            permission: 'DEPARTMENTS',

          },
          {
            subMenuTitle: 'Parameters',
            icon: 'cluster',
            route: '/administration/masters/parameters',
            permission: 'PARAMETERS',
          },
          {
            subMenuTitle: 'Conditions',
            icon: 'cluster',
            route: '/administration/masters/conditions',
            permission: 'CONDITIONS',
          }
        ]
      },
      {
        mainMenu: 'Settings',
        icon: 'setting',
        permission: true,
        subMenuItems: [
          // {
          //   subMenuTitle: 'Company Information',
          //   icon: 'info-circle',
          //   route: '/administration/settings/company-information',
          //   permission: 'COMPANY_INFORMATION',
          // },
          // {
          //   subMenuTitle: 'Templates',
          //   icon: 'mail',
          //   route: '/administration/settings/mail-templates',
          //   permission: 'TEMPLATES',

          // },
          {
            subMenuTitle: 'Preferences',
            icon: 'control',
            route: '/administration/settings/preferences',
            permission: 'PREFERENCES',

          },
          // {
          //   subMenuTitle: 'Email Settings',
          //   icon: 'mail',
          //   route: '/administration/settings/email',
          //   permission: 'EMAIL',
          // },
          // {
          //   subMenuTitle: 'ADR Configurations',
          //   icon: 'appstore',
          //   route: '/administration/settings/adr-configurations',
          //   permission: 'ADR_CONFIGURATIONS',
          // },
          // {
          //   subMenuTitle: 'Manage Form Fields',
          //   icon: 'insert-row-above',
          //   route: '/administration/settings/manage-form',
          //   permission: 'MANAGE_FORM_FIELDS',

          // },
          // {
          //   subMenuTitle: 'Health Authority Configurations',
          //   icon: 'file-sync',
          //   route: '/administration/settings/health-authority-configurations',
          //   permission: 'HEALTH_AUTHORITY_CONFIGURATIONS',

          // },
          // {
          //   subMenuTitle: 'Timeline Settings',
          //   icon: 'snippets',
          //   route: '/administration/settings/timeline-settings',
          //   permission: 'TIMELINE_SETTINGS',
          // }
        ]
      }
    ];
    if(localStorage.getItem('roleName') != 'SuperAdmin'){
    this.getMenuItemsByPermissions(menuItemss); 
    }else{
    this.menuItemss = menuItemss;
    }
  }

  loadLiteratureMonitoringItems() {
    let menuItems = [];
    menuItems = [
      {
        mainMenu: 'Dashboard',
        route: '/literature-monitoring/dashboard',
        icon: 'home',
        permission: 'LITERATURE_MONITORING',
        select: 'true'
      },
      {
        mainMenu: 'Reports',
        icon: 'file',
        route: '/literature-monitoring/reports',
        permission: 'LITERATURE_MONITORING'
      }
    ];
    this.getMenuItemsByPermissions(menuItems);   
  }

  navigateMainRoute(menu: any) {
    // if(menu.mainMenu == 'Home') {
    //   console.log("Home Click");
    //   this.healthAuthorityService.getCount(true);
    // }
    if (menu.route) {
      this.router.navigateByUrl(menu.route);
      this.userService.selectedTabIndex = 0;
      this.userRoleService.selectedTabIndex = 0;
    }
  }

  isMenuOpen(menu: any){
    let IsOpen: any = false;
    menu.subMenuItems.forEach((submenu: any) => {
      if(submenu.subMenuTitle == this.selectedSubMenuItem){
        IsOpen = true;
      }
    });
    return IsOpen;
  }

  navigateSubRoute(route: any) {
    this.router.navigateByUrl(route);
  }

  moduleSelection() {
    this.commonService.getmodulecasestaus(this.selectedModule.id, this.currentUser.user_role_id).subscribe((response: any) => {
      this.currentUser.sendCaseStatus = response[0]?.sendCaseStatus;
      this.currentUser.recieveCaseStatus = response[0]?.recieveCaseStatus;
      this.currentUser.recieveFAQStatus = response[0]?.recieveFAQStatus;
      this.currentUser.sendFAQStatus = response[0]?.sendFAQStatus;
      this.currentUser.sendDocumentStatus = response[0]?.sendDocumentStatus;
      this.currentUser.recieveDocumentStatus = response[0]?.recieveDocumentStatus;
      this.storageService.setItem('userDetails', JSON.stringify(this.currentUser));
      if(response[0].isLiteratureMonitoring == 1){
        this.router.navigate(['/literature-monitoring/dashboard']);
      }
      else {
        switch (this.selectedModule.id) {
          case 1:
            this.router.navigate(['/case-management-system/dashboard/sources/phone']);
            break;
          case 2:
            if(this.currentUser.recieveCaseStatus == "CASEINTAKE_INITIATED"){
              if(this.permissonsService.hasAccess(['ASSIGN_CASE'])){
                this.router.navigate(['/medical-enquiry/cases-assign']);
              }
              else if(this.permissonsService.hasAccess(['FAQ_INITIATION']) || this.permissonsService.hasAccess(['FAQ_REVIEW'])
              || this.permissonsService.hasAccess(['FAQ_APPROVE'])){
                this.router.navigate(['/medical-enquiry/faq']); 
              }
              else if(this.permissonsService.hasAccess(['DOCUMENT_INITIATION']) || this.permissonsService.hasAccess(['DOCUMENT_REVIEW'])
              || this.permissonsService.hasAccess(['DOCUMENT_APPROVE'])){
                this.router.navigate(['/medical-enquiry/documents-repo']);
              }
              else if(this.permissonsService.hasAccess(['PRODUCT_DICTIONARY']) || this.permissonsService.hasAccess(['PRODUCT_DICTIONARY'])
              || this.permissonsService.hasAccess(['PRODUCT_DICTIONARY'])){
                this.router.navigate(['/medical-enquiry/product-dictionary']);
              }
              else{
                this.alertService.alertMessage("error", "Access Restricted", "You don't have permission to access this feature or module.!");
              }
            }
            else{
              this.router.navigate(['/medical-enquiry/dashboard']);
            }
            break;
          case 3:
            this.router.navigate(['/administration/admin/user-registration']);
            break;
          case 4:
            this.router.navigate(['/administration/admin']);
            break;
          default:
            break;
        }
      }  
    }, error => {

    });

  }

  ModulePermissions() {
    this.authService.getModulePermissions(this.authService.getUserData().user_role_id).subscribe((res: any) => {
      this.moduleNames = res['modules'];
      const index = this.moduleNames.findIndex((x: any) => x.moduleName == 'Administration')
      let id = this.moduleNames[index]?.id;
      if(id){
        this.moduleForm.controls.moduleName.setValue(id);
        this.storageService.setItem("userData", JSON.stringify(this.moduleNames[0]));
      }
      else{
        this.router.navigateByUrl('/module-section');
      }
    })
  }

  onModulechange() {
    const index = this.moduleNames.findIndex((x: any) =>x.id == this.moduleForm.value.moduleName);
    this.selectedModule = this.moduleNames[index];

    this.authService.getPermissions(this.authService.getUserData().user_role_id, this.selectedModule.id).subscribe((response: any) => {
      let permissions = response.map((x: any) => x.permission);
      this.storageService.setItem('permissions', permissions);
      
      this.storageService.setItem("moduleName", this.selectedModule.moduleName);
      this.storageService.setItem("moduleId",this.selectedModule.id);
      this.moduleSelection();
    });

  }
}
