import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sb-pdf-file-viewer',
  templateUrl: './pdf-file-viewer.component.html',
  styleUrls: ['./pdf-file-viewer.component.less']
})
export class PdfFileViewerComponent implements OnInit {
  @Input() file: any;
  @Input() filename: any;
  constructor() { }

  ngOnInit(): void {
  }

}
