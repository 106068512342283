import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {

  selectedTabIndex: any = 0;

  POST_PARAMETER_URL = 'product/parameter/create';
  UPDATE_PARAMETER_URL = `product/parameter/update`;
  GET_PARAMETER_URL = `product/parameter/list`
  GET_PRAMETER_BY_ID = `product/parameter`


  constructor(private httpClient: HttpClient) { }


  postParameter(body: any) {
    return this.httpClient.post(this.POST_PARAMETER_URL, body)
  }
  updateParameter(body: any) {
    return this.httpClient.put(this.UPDATE_PARAMETER_URL, body)
  }
  getParameterList(activeStatus:any){
    return this.httpClient.get(`${this.GET_PARAMETER_URL}?type=${activeStatus}`)
  }
  getParameterById(id:any){
    return this.httpClient.get(`${this.GET_PRAMETER_BY_ID}/${id}`)
  }
  
}
