<div class="d-flex mb-4">
    <div class="border pt-3 bg-white px-2 pb-1 reviewer-header pos-relative">
        <span class="block-heading">Sources Total</span>
        <ul>
            <li class="text-center" [ngClass]="{active:(selectedName==data?.name)}" (click)="selectedSource(data)"
                *ngFor="let data of sourceList">
                <a *ngIf="data.id!=null">
                    <strong class="d-block"><span>{{data.count}}</span></strong>
                    <small class="d-block">{{data.name}}</small>
                </a>
            </li>
            <li class="text-center">
                <a href="javascript:void(0)">
                    <strong class="d-block">{{sourceListTotal}}</strong>
                    <small class="d-block">Total</small>
                </a>
            </li>
        </ul>
    </div>
    <div class="px-3">
        <div class="border pt-3 bg-white px-2 pb-1 reviewer-header pos-relative">
            <span class="block-heading">Classification</span>
            <ul>
                <li class="text-center" [ngClass]="{active:(selectedClassification==data?.name)}"
                    (click)="selectedData(data)" *ngFor="let data of classificationData">
                    <a *ngIf="data.id!=null">
                        <strong class="d-block" >{{data.count}}</strong>
                        <small class="d-block">{{data.name}}</small>
                    </a>
                </li>
                <li class="text-center">
                    <a href="javascript:void(0)">
                        <strong class="d-block">{{classificationData[0].count + classificationData[1].count +
                            classificationData[2].count}}</strong>
                        <small class="d-block">Total</small>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="border pt-3 bg-white px-2 pb-1 reviewer-header pos-relative">
        <span class="block-heading">Validity</span>
        <ul>
            <li class="text-center">
                <a href="javascript:void(0)">
                    <strong class="d-block">10</strong>
                    <small class="d-block">Rejected</small>
                </a>
            </li>
        </ul>
    </div>
</div>