import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserRegistrationService {

  GET_USERS_URL = 'admin/users';
  GET_RESOURCE_URL = 'admin/role/getroles';
  GET_USERS_RESOURCE_URL = 'admin/user/getusers';
  GET_POLICY_SETTINGS_URL = 'admin/policy-settings';
  POST_USER_URL = 'admin/create-user';
  UPDATE_USER_URL = 'admin/update-user';
  DEACTIVATE_USER_RESOURCE_URL = `admin/de-activateuser`;
  selectedTabIndex: any = 0;
  GET_ACTIVE_USERS_URL = 'admin/user/active-users';
  GET_INACTIVE_USERS_URL = 'admin/user/inactive-users';
  GET_EMAIL_INFO_URL = `admin/get-email-info`;
  constructor(private httpClient: HttpClient) { }

  getUsers() {
      return this.httpClient.get(this.GET_USERS_URL);
  }

  getActiveUsers() {
    return this.httpClient.get(this.GET_ACTIVE_USERS_URL);
  }

  getInActiveUsers() {
    return this.httpClient.get(this.GET_INACTIVE_USERS_URL);
  }

  getRoles(status:any) {
    return this.httpClient.get(this.GET_RESOURCE_URL + '?status=' + status);
  }

  getRoleById(id: any) {
    return this.httpClient.get(this.GET_RESOURCE_URL + '/' + id);
  }

  getUsersList(value: any) {
    return this.httpClient.get(this.GET_USERS_RESOURCE_URL + '?status=' + value);
  }


  getPolicySettingsData() {
    return this.httpClient.get(this.GET_POLICY_SETTINGS_URL);
  }

  postUser(body:any) {
    return this.httpClient.post(this.POST_USER_URL,body);
  }
  updateUser(body:any) {
    return this.httpClient.put(this.UPDATE_USER_URL,body);
  }
  getUserDataById(id: any){
    return this.httpClient.get(this.GET_USERS_RESOURCE_URL + '/' + id );
  }
  deActivateUser(id: any, formData: any, status: any) {   
    return this.httpClient.put(this.DEACTIVATE_USER_RESOURCE_URL + `/${id}/${status}`, formData);
}
getEmailInfo() {
  return this.httpClient.get(this.GET_EMAIL_INFO_URL)
}
}