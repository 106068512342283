<div class="px-8">
    <div nz-row [nzGutter]="24">
        <div nz-col class="gutter-row" [nzSpan]="24">
            You're being timed out due to inactivity. Please choose to stay signed in or logoff. Otherwise, you will
            logged off automatically.
        </div>
    </div>
    <div *nzModalFooter>
        <div nz-row class="" nzJustify="center">
            <button (click)="logout()" nz-button class="ml-10" nzType="primary"><i nz-icon nzType="send"
                    nzTheme="outline"></i>LogOut</button>
            <button (click)="cancel()" nz-button class="ml-10" nzType="default"><i nz-icon nzType="close"
                    nzTheme="outline"></i>Cancel</button>

        </div>
    </div>
</div>