// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverUrl: 'http://139.59.36.66:8000/api/',
  baseUrl: "https://stabilityreport-node.scriptbees.com/api/",
  lmsServerUrl: "https://lm-dev.scriptbees.com/",
  healthAuthorityReconcilationUrl: 'http://localhost:5000/api/',
  // pythonbaseurl:"http://139.59.36.66:5001/api/flask/",
  pythonbaseurl:"http://127.0.0.1:5000/api/flask/",

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
