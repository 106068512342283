import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuditTrailsService {

  GET_AUDIT_TRAILS_URL = 'admin/get-audits';
  POST_AUDIT_TRAILS_URL = 'admin/save-audits';
  GETAll_AUDIT_TRAILS_URL = `admin/audit-trails`;
  GET_AUDIT_NAMES_URL = 'admin/get-auditnames';

  constructor(private http: HttpClient) { }

  getAuditTrails(body: any) {
    return this.http.post(this.GET_AUDIT_TRAILS_URL, body);
  }

  getAllAudits() {
    return this.http.get(this.GETAll_AUDIT_TRAILS_URL);
  }
  
  postAuditTrails(body: any) {
    return this.http.post(this.POST_AUDIT_TRAILS_URL, body)
  }

  getAuditNames() {
    return this.http.get(this.GET_AUDIT_NAMES_URL)
  }

}
