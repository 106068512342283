import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { PermissonsService } from 'src/app/core/services/permissions.service';
import { StorageService } from 'src/app/services/storage.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-module-section',
  templateUrl: './module-section.component.html',
  styleUrls: ['./module-section.component.less']
})
export class ModuleSectionComponent implements OnInit {
  gridStyle = {
    width: '25%',
    textAlign: 'center'
  };
  moduleNames:any=[];
  CurrentUSer: any;
  logo: any;
  logoValue:boolean = false;
  constructor(private authService:AuthService, private router:Router,
    private commonService: CommonService,
    private alertService: AlertService,
    private permissonsService: PermissonsService,
    private storageService:StorageService) { 
      this.CurrentUSer = this.authService.getUserData();
    }

  ngOnInit(): void {
    if(this.storageService.getItem('preferencesLogo')!=null || undefined) {
      this.logo = this.storageService.getItem('preferencesLogo');
      this.logoValue = true;
    }
    this.ModulePermissions();
  }

  moduleSelection(data:any) {
    this.authService.getPermissions(this.authService.getUserData().user_role_id, data.id).subscribe((response: any) => {
      let permissions = response.map((x: any) => x.permission);
      this.storageService.setItem('permissions', permissions);
      
      this.storageService.setItem("moduleName",data.moduleName);
      this.storageService.setItem("moduleId",data.id);
      this.commonService.getmodulecasestaus(data.id, this.CurrentUSer.user_role_id).subscribe((response: any) => {
        this.CurrentUSer.sendCaseStatus = response[0]?.sendCaseStatus;
        this.CurrentUSer.recieveCaseStatus = response[0]?.recieveCaseStatus;
        this.CurrentUSer.recieveFAQStatus = response[0]?.recieveFAQStatus;
        this.CurrentUSer.sendFAQStatus = response[0]?.sendFAQStatus;
        this.CurrentUSer.sendDocumentStatus = response[0]?.sendDocumentStatus;
        this.CurrentUSer.recieveDocumentStatus = response[0]?.recieveDocumentStatus;
        this.storageService.setItem('userDetails', JSON.stringify(this.CurrentUSer));
        if(response[0].isLiteratureMonitoring == 1){
          this.router.navigate(['/literature-monitoring/dashboard']);
        }
        else {
          switch (data.id) {
            case 1:
              // this.router.navigate(['/case-management-system/cases']);
              this.router.navigate(['/case-management-system/dashboard/sources/phone']);
              break;
            case 2:
              if(this.CurrentUSer.recieveCaseStatus == "CASEINTAKE_INITIATED"){
                if(this.permissonsService.hasAccess(['ASSIGN_CASE'])){
                  this.router.navigate(['/medical-enquiry/cases-assign']);
                }
                else if(this.permissonsService.hasAccess(['FAQ_INITIATION']) || this.permissonsService.hasAccess(['FAQ_REVIEW'])
                || this.permissonsService.hasAccess(['FAQ_APPROVE'])){
                  this.router.navigate(['/medical-enquiry/faq']); 
                }
                else if(this.permissonsService.hasAccess(['DOCUMENT_INITIATION']) || this.permissonsService.hasAccess(['DOCUMENT_REVIEW'])
                || this.permissonsService.hasAccess(['DOCUMENT_APPROVE'])){
                  this.router.navigate(['/medical-enquiry/documents-repo']);
                }
                else if(this.permissonsService.hasAccess(['PRODUCT_DICTIONARY']) || this.permissonsService.hasAccess(['PRODUCT_DICTIONARY'])
                || this.permissonsService.hasAccess(['PRODUCT_DICTIONARY'])){
                  this.router.navigate(['/medical-enquiry/product-dictionary']);
                }
                else{
                  this.alertService.alertMessage("error", "Access Restricted", "You don't have permission to access this feature or module.!");
                }
              }
              else{
                this.router.navigate(['/medical-enquiry/dashboard']);
              }
              break;
            case 3:
              this.router.navigate(['/administration/admin/user-registration']);
              break;
            case 4:
              this.router.navigate(['/administration/admin']);
              break;
            default:
              break;
          }
        }
      }, error => {

      });

    });
  }

  ModulePermissions() {
    this.authService.getModulePermissions(this.authService.getUserData().user_role_id).subscribe((res: any) => {


      
      this.moduleNames=res['modules'];
      console.log('data',this.moduleNames)

      this.storageService.setItem("userData", JSON.stringify(this.moduleNames['modules'][0]))
    })
  }
}
