import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-case-handlers',
  templateUrl: './case-handlers.component.html',
  styleUrls: ['./case-handlers.component.less']
})
export class CaseHandlersComponent implements OnInit {
  isVisible = false;
  @Input('isCaseForm') isCaseForm: any;
  @Input('isAssign') isAssign:any;
  @Input('title') title: any;
  @Input('isDuplicateSearch') isDuplicateSearch: any;
  @Input('isViewCases') isViewCases: any;
  showViewCase = false;
  validateForm!: FormGroup;
  inputValue?: string;
  nzOkText:null;
  userData: any;
  userStatus: any;
  constructor(private fb: FormBuilder, private authService: AuthService) {
    this.userData = this.authService.getUserData();
    this.userStatus = this.authService.getUserData().recieveCaseStatus;
   }

  showModal(): void {
    this.isVisible = true;
    
  }
  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }
  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: [null, [Validators.email, Validators.required]],
      subject: ['', [Validators.required]]
    });
  }


}
