import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PlantMasterService } from '../plant-master.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { CommonService } from 'src/app/core/services/common.service';
import { AuditTrailsService } from 'src/app/administration/audit-trails/audit-trails.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-plant-master-form',
  templateUrl: './plant-master-form.component.html',
  styleUrls: ['./plant-master-form.component.less']
})
export class PlantMasterFormComponent implements OnInit {
  @Input() id: any;
  plantMasterForm: any = FormGroup;
  isSignatureModal = false;
  auditObject: any = {};
  // id: any;
  isDeactivate: boolean = false;
  isActivate: boolean = false;
  plantStatus: any;
  errorMessage: string = "";
  originalValues: any;

  constructor(private fb: FormBuilder, private plantService: PlantMasterService, private router: Router, private message: NzMessageService,private commonService: CommonService,
    private messageService: AlertService,private storageService:StorageService, private readonly authservice: AuthService, private readonly activatedRoute: ActivatedRoute, private modalService: NzModalService,
    private auditService: AuditTrailsService) {
    // this.id = this.activatedRoute.snapshot.params['plantId'];
    // const ref: NzModalRef = modalService.info();
    // ref.close();


  }

  ngOnInit(): void {

    this.getPlantMasterForm();
    if (this.id) {
      this.getPlantMasterById();
    }

  }

  getPlantMasterById() {
    this.plantService.getPlntMasterDataById(this.id).subscribe((res: any) => {
      this.plantStatus = res.active; 
      this.originalValues = res;
      delete this.originalValues.active;
      this.plantMasterForm.patchValue(res);
      if(res.active == 0) {
        this.isActivate = true;
      }
      else {
        this.isActivate = false;
      }
    }, error => {
      console.log(error);
    });
  }
  getPlantMasterForm() {
    this.plantMasterForm = this.fb.group({
      id: [''],
      plant_name: [null, [Validators.required]],
      plant_location: [null, [Validators.required]]
    })
  }


  cancel() {
    // this.router.navigateByUrl('/administration/masters/plant-master')
    this.closePopup();
  }

  SubmitButton(data: any, type?: any) {
    if(type == 'save') {
      this.postPlantMaster(data, type);
    }
    else {
        this.postPlantMaster(data,type);
      }
  }

  updatePlantMaster(data: any, type: any) {
    for (const i in data.controls) {
      data.controls[i].markAsDirty();
      data.controls[i].updateValueAndValidity();
    }
    if (data.valid) {
      if (type == 'deactivate') {
        this.isDeactivate = true;
      } else {
        this.isDeactivate = false;
      }
      if(this.originalValues.plant_name!=this.plantMasterForm.value.plant_name || this.originalValues.plant_location!=this.plantMasterForm.value.plant_location) {
        this.isSignatureModal = true;
      }  
    }
  }


  postPlantMaster(data: any, type: any) {
    for (const i in data.controls) {
      data.controls[i].markAsDirty();
      data.controls[i].updateValueAndValidity();
    }
    if (data.valid) {
      if (type == 'deactivate') {
        this.isDeactivate = true;
      } else {
        this.isDeactivate = false;
      }
      let body = {
        plant_name: data.controls.plant_name.value,
        plant_location: data.controls.plant_location.value
      }
      this.isSignatureModal = true;
    }
  }


  authenticateEvent(event: any) {
    if (!this.id) {
      this.plantService.postPlants(this.plantMasterForm.value).subscribe((res: any) => {
        if(res.message == 'Plant Already Exists') {
          this.errorMessage = res.message;
          setTimeout(() => {
            this.errorMessage = '';
          }, 3000);
        }
        else {
          this.saveAuditTrialsObject(`Created the plant '${this.plantMasterForm.controls.plant_name.value}' with the location '${this.plantMasterForm.controls.plant_location.value}'` ,event.remarks, `Plant Master Audit Trails`);
          this.messageService.alertMessage("success", "Success", "Plant Added Successfully");
          this.closePopup();
        } 
      }, error => {
        console.log('error');

      });
      this.isSignatureModal = false;
    } else {
      if (this.isDeactivate) {
        this.DeactivatePlant(event);
      } else {
        let changedValue: any = [];
        let body = {
          values:this.plantMasterForm.value,
          originalValues: this.originalValues 
        }
        if(this.plantMasterForm.value!=this.originalValues) {
          if(this.originalValues.plant_name!=this.plantMasterForm.value.plant_name) {
            changedValue.push(`Plant Name from '${this.originalValues.plant_name}' to '${this.plantMasterForm.value.plant_name}'`)
          }
          if(this.originalValues.plant_location!=this.plantMasterForm.value.plant_location) {
            changedValue.push(`Plant Location from '${this.originalValues.plant_location}' to '${this.plantMasterForm.value.plant_location}'`)
          }
          this.plantService.updatePlants(body).subscribe((res: any) => {
            if(res.message == 'Duplicate Plant') {
              this.errorMessage = res.message;
              setTimeout(() => {
                this.errorMessage = '';
              }, 3000);
            }
            else {
              this.saveAuditTrialsObject(`changed "${changedValue.toString()}"` ,event.remarks, `Plant Master Audit Trails`);
              this.messageService.alertMessage("success", "Success", "Plant Updated Successfully");
              this.closePopup();
            }  
          }, error => {
            console.log('error');
  
          });
          this.isSignatureModal = false;
        }
      }
    } setTimeout(() => {
      // this.router.navigateByUrl('/administration/masters/plant-master');
   
    }, 3000);
  }
  DeactivatePlant(event: any) {
    const plantname = this.plantMasterForm.controls.plant_name.value;
    let activateName: any;
    if(this.plantStatus == 1) {
      activateName = 'DeActivated'
    }
    else if(this.plantStatus == 0) {
      activateName = 'Activated'
    }
    this.plantService.activateDeActivatePlant(this.id, this.plantStatus, this.plantMasterForm.value).subscribe((res: any) => {
      this.saveAuditTrialsObject(` ${activateName} The Plant '${plantname}'`, event.remarks, `Plant Master Audit Trails`);
      this.messageService.alertMessage("success", "Success", `Plant ${activateName} Successfully`);
      this.closePopup();

    }, error => {
      console.log('error');

    });
    this.isSignatureModal = false;
    setTimeout(() => {
      // this.router.navigateByUrl('/administration/masters/plant-master');
   
    }, 3000);
  }

  closeSignatureModal(event: any) {
    this.isSignatureModal = event;
  }
  closePopup() {
    this.modalService.closeAll();
    this.commonService.getApisData(true);
  }

  activateDeActivatePlant(data: any, type: any) {
    for (const i in data.controls) {
      data.controls[i].markAsDirty();
      data.controls[i].updateValueAndValidity();
    }
    if (data.valid) {
      this.isDeactivate = true;
      this.isSignatureModal = true;
    }
  }

  saveAuditTrialsObject(activity: any, remarks: any, typeOfAudit: any) {
    this.auditObject = {
      activity: this.storageService.getItem('roleName') + ' ' + activity,
      remarks: remarks,
      typeOfAudit: typeOfAudit,
      ipAddress: this.storageService.getItem('IpAddress'),
      status: 'ACTIVITY_COMPLETED',
      userId: this.storageService.getItem('user_login_id'),
      userName: this.storageService.getItem('username')
    }
    this.auditService.postAuditTrails(this.auditObject).subscribe((res: any) => {
    }, (error: any) => {
      console.log(error);
    })
  }
}
