<app-loader></app-loader>
<!-- <ngx-ui-loader></ngx-ui-loader> -->
<router-outlet></router-outlet>

<nz-modal [(nzVisible)]="isTimeoutModal" nzClassName="modal-width-30 timeouthead" nzTitle="You're being timed out due to inactivity"
    (nzOnCancel)="stay()">
    <div class="px-8">
        <div nz-row [nzGutter]="24">
            <div nz-col class="gutter-row" [nzSpan]="24">
                Please choose to stay signed in or logoff. Otherwise, you will
                logged off automatically.
            </div>
        </div>
        <div *nzModalFooter>
            <div nz-row class="" nzJustify="end">
                <button (click)="stay()" nz-button class="ml-10" nzType="default"><i nz-icon nzType="close"
                    nzTheme="outline"></i>Cancel</button>
                <button (click)="logout()" nz-button class="ml-10" nzType="primary"><i nz-icon nzType="logout"
                    nzTheme="outline"></i>Logout</button>
            </div>
        </div>
    </div>
    </nz-modal>