import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { CaseService } from '../../services/cases.service';
import { Bar, Column } from '@antv/g2plot';
import { Pie } from '@antv/g2plot';
import { AuthService } from 'src/app/core/services/auth.service';



@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.less']
})
export class StatisticsComponent implements OnInit {

  classificationData: any = [];
  sourceList: any = [];
  validityData: any = [];
  countsData: any = [];
  classificationNames: any = [];
  classificationCount: any = [];
  casesListData: any = [];
  sourcesNames: any = [];
  sourcesCount: any = [];
  sourcesListData: any = [];
  dataLength: any;
  userData: any = [];
  caseClassificationData: any = [];
  caseSourcesData:any = [];
  sourcesData:any = [];
  status:any;
  optionValue = 'all';
  dataValue = 'all';
  selectedGraphValue:any;



  constructor(private commonService: CommonService, private caseService: CaseService,private authService: AuthService) {
  }


  ngOnInit() {
    this.getCasesCounts();
  }
  
  // valueChange(event:any) {
  //   this.optionValue = this.selectedGraphValue;
  //   console.log("Event",event)
  //   console.log("this.optionValue",this.optionValue)
  //   setTimeout(() => {
  //     this.ngOnInit();
  //   }, 2000); 
  // }

  getCasesCounts() {
    this.userData = this.authService.getUserData()['id'];
    this.commonService.getCasesCounts(this.authService.getUserData()['recieveCaseStatus'], this.userData).subscribe((res: any) => {
      this.classificationData = res.classificationsData;
      this.sourcesData = res.sourcesData;
      this.bindClassficationsData();
      this.bindSourcesData();
    }, (error: any) => {
      console.log(error)
    });
  }

  bindClassficationsData() {
    this.classificationData.forEach((element: any) => {
      if (element.id) {
        this.caseClassificationData.push({ type: element.name, value: element.count });
      }
    });
    const MIColor = '#007bff';
    const AEColor = '#20c997';
    const PQCColor = '#FEC601';
    const data = this.caseClassificationData;
    const piePlot = new Pie('container', {
      appendPadding: 10,
      data,
      angleField: 'value',
      colorField: 'type',
      radius: 1,
      innerRadius: 0.64,
      meta: {
        value: {
          formatter: (v: any) => `${v}`,
        },
      },
      label: {
        type: 'inner',
        offset: '-50%',
        autoRotate: false,
        style: { textAlign: 'center' },

      },
      statistic: {
        title: {
          offsetY: -8,
        },
        content: {
          offsetY: -4,
        },
      },
      interactions: [
        { type: 'element-selected' },
        { type: 'element-active' },
        {
          type: 'pie-statistic-active',
          cfg: {
            start: [
              { trigger: 'element:mouseenter', action: 'pie-statistic:change' },
              { trigger: 'legend-item:mouseenter', action: 'pie-statistic:change' },
            ],
            end: [
              { trigger: 'element:mouseleave', action: 'pie-statistic:reset' },
              { trigger: 'legend-item:mouseleave', action: 'pie-statistic:reset' },
            ],
          },
        },
      ],
    });

    // const bar = new Bar('barchart', {
    //   data,
    //   xField: 'value',
    //   yField: 'type',
    //   seriesField: '',
    //   color: ({ type }) => {
    //     if (type === 'MI') {
    //       return MIColor;
    //     }
    //     if (type === 'PQC') {
    //       return PQCColor;
    //     }
    //     return AEColor;
    //   },
    //   legend: false,
    //   xAxis: {
    //     label: {
    //       autoHide: true,
    //       autoRotate: false,
    //     },
    //   },
    // });

    const columnPlot = new Column('barchart', {
      data,
      xField: 'type',
      yField: 'value',
      seriesField: '',
      minColumnWidth: 1,
      color: ({ type }) => {
        if (type === 'MI') {
          return MIColor;
        } else if (type === 'PQC') {
          return PQCColor;
        } else {
          return AEColor;
        }
      },
      legend: false,
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      }
    });
    
    columnPlot.render();
    piePlot.render();
  }

  bindSourcesData() {
    this.sourcesData.forEach((element: any) => {
      if (element.id) {
        this.caseSourcesData.push({ type: element.name, value: element.count });
      }
    });
    const MIColor = '#007bff';
    const AEColor = '#20c997';
    const PQCColor = '#FEC601';
    const LColor = '#5d7092';
    const  SColor='#6f5ef9';
    const  LMColor = '#6dc8ec';
    const HAColor = '#945fb9';
    const CWColor ='#ff9845';
    const OColor = '#1e9493';
   
    const data = this.caseSourcesData;
    const piePlot = new Pie('sourcesData', {
      appendPadding: 10,
      data,
      angleField: 'value',
      colorField: 'type',
      radius: 1,
      innerRadius: 0.64,
      meta: {
        value: {
          formatter: (v: any) => `${v}`,
        },
      },
      label: {
        type: 'inner',
        offset: '-50%',
        autoRotate: false,
        style: { textAlign: 'center' },

      },
      statistic: {
        title: {
          offsetY: -8,
        },
        content: {
          offsetY: -4,
        },
      },
      interactions: [
        { type: 'element-selected' },
        { type: 'element-active' },
        {
          type: 'pie-statistic-active',
          cfg: {
            start: [
              { trigger: 'element:mouseenter', action: 'pie-statistic:change' },
              { trigger: 'legend-item:mouseenter', action: 'pie-statistic:change' },
            ],
            end: [
              { trigger: 'element:mouseleave', action: 'pie-statistic:reset' },
              { trigger: 'legend-item:mouseleave', action: 'pie-statistic:reset' },
            ],
          },
        },
      ],
    });

    const columnPlot = new Column('Sourcesbarchart', {
      data,
      xField: 'type',
      yField: 'value',
      seriesField: '',
      minColumnWidth: 1,
      color: ({ type }) => {
        if (type === 'Phone') {
          return MIColor;
        } else if (type === 'Email') {
          return AEColor;
        }
        else if (type === 'Letter') {
          return LColor;
        }
        else if (type === 'Fax') {
          return PQCColor;
        } else if (type === 'Social Media') {
          return SColor;
        } else if (type === 'Literature Monitoring') {
          return LMColor;
        }else if (type === 'Health Authority') {
          return HAColor;
        }else if (type === 'Company Website') {
          return CWColor;
        }
        else {
          return OColor;
        }
      },
      legend: false,
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      }
    });
    columnPlot.render();
    piePlot.render();
  }

}
