import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'sb-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.less']
})
export class ButtonLoaderComponent implements OnInit {
  isLoadingOne = false;
  @Input() message: any;
  @Output() emitedvalue = new  EventEmitter<any>();
  text: any;
  @Input() data: any;
  constructor(private readonly commonservice: CommonService) { }

   ngOnInit(): void {
     this.isLoadingOne = true;
  }
}
