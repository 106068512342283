import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'sb-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.less']
})
export class SessionTimeoutComponent implements OnInit {
  @ViewChild('sessionPopup') public sessionPopup: any;
  logoutInterval: any;
  constructor(
    private readonly router: Router,private authService: AuthService,private modalService: NzModalService
  ) {}

  ngOnInit(): void {
  }
  logout() {
    this.authService.logout();
    this.closePopup();
  }
  cancel() {
    this.closePopup();
  }
  closePopup() {
    this.modalService.closeAll();
  }

}
