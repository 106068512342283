import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';
import { CommonService } from './common.service';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { PolicySettingsService } from 'src/app/administration/policy-settings/policy-settings.service';
import { StorageService } from 'src/app/services/storage.service';
import { CookieService } from 'ngx-cookie';


@Injectable({
  providedIn: 'root',
})
export class AuthService {

  GET_USERS_URL = 'users/';
  GET_LOGIN_URL = 'auth/login';
  POST_PERMISSIONS_URL = 'auth/permissions/';
  GET_MODULES_PERMISSIONS_URL = 'auth/modules/';
  currentUserValue: any;
  LOG_OUT='auth/logout';
  GET_USERID_URL ='auth/user-id';
  GET_USER_BY_ID_URL ='auth/get-user-by-id';
  GET_HEALTH_AUTHORITY_SCREENSHOT =`auth/screenshot`;
  CSRF_TOKEN_URL = `get/csrf`;
  isNewLogin: boolean = false;
  newUserId: any;
  private userLoggedIn = new Subject<boolean>();

  constructor(private http: HttpClient, private commonService: CommonService, private router: Router,private cookieService: CookieService,
    private bnIdle: BnNgIdleService, private policySettingsService: PolicySettingsService, private storageService:StorageService) {
      this.userLoggedIn.next(false);
  }

  login(body: any) {
    return this.http.post<any>(this.GET_LOGIN_URL, body);
  }

  getPermissions(roleId: any, moduleId: any) {
    return this.http.get<any>(this.POST_PERMISSIONS_URL + roleId + `/${moduleId}`)
  }

  getUsers(rolename: any) {
    return this.http.get(this.GET_USERS_URL + rolename);
  }

  getUseridData(userId:any) {
    return this.http.get(this.GET_USERID_URL+ '/' + userId)
  }

  getUserByID(userId:any) {
    return this.http.get(this.GET_USER_BY_ID_URL+ '/' + userId)
  }

  logout() {
    this.setUserLoggedIn(false);
    let data:any=this.storageService.getItem('userDetails') != null ? this.storageService.getItem('userDetails') : sessionStorage.getItem('userDetails')
    let userData=JSON.parse(data)
    let body={
      "id":userData.userId,
      "loginKey":userData.loginKey
    }
    return this.http.post(this.LOG_OUT,body).subscribe((response)=>{
      this.storageService.removeItem('userDetails');
      this.storageService.removeItem('username');
      this.storageService.removeItem('userData');
      this.storageService.removeItem('user_login_id');
      this.storageService.removeItem('user_login_id');
      this.storageService.removeItem('setTimeout');
      this.storageService.removeItem('permissions');
      this.storageService.removeItem('preferencesLogo');
      this.cookieService.remove('token');
      localStorage.clear();
      this.router.navigate(['login']);
      // this.bnIdle.stopTimer();
      clearInterval(this.policySettingsService.interval);
    })
  }

  getUserData() {
    let data: any = this.storageService.getItem(`userDetails`);
    return JSON.parse(data);
  }


  getModulePermissions(roleId:any) {
    return this.http.get<any>(this.GET_MODULES_PERMISSIONS_URL + roleId)
  }
  getHealthauthorityScreenshot(data:any){
    return this.http.post(this.GET_HEALTH_AUTHORITY_SCREENSHOT,data)
  }
  csrfToken(){
    return this.http.get(this.CSRF_TOKEN_URL + "/" + this.getUserData().userId);
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

}
