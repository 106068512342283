<!-- <div [hidden]="!isLoading" class="text-center loading-wrapper">
    <div class="message-container" [@fadeInOut]>
      <p class="m-0">
        <i class="fas fa-circle-notch fs-20 fa-spin mr-40"></i> Your Request is Being Processed, Please Wait!!!
      </p>
    </div>
  </div> -->
<div *ngIf="show" class="loaderMask">
  <nz-alert class="alert" nzType="info" nzMessage="Your request is processing...Please Wait...!"></nz-alert>
</div>
