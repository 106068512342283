import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissonsService } from 'src/app/core/services/permissions.service';

@Directive({
  selector: '[HasAnyAuthority]'
})
export class HasAuthorityDirective {
  private authorities!: string[];
  
  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly permissionService: PermissonsService
  ) {}

  @Input()
  set HasAnyAuthority(value: string | Array<string>) {
    this.authorities = typeof value === 'string' ? [value] : value;
    if (this.permissionService.hasAccess(this.authorities)) {
      this.updateView(true);
    }
  }

  private updateView(hasAccess: boolean): void {
    this.viewContainerRef.clear();
    if (hasAccess) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
