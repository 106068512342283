<div class="px-8">
  
        <form nz-form [formGroup]="departmentForm" class="departmentForm py-20 text-left">
            <nz-alert nzType="error" class="pb-20" *ngIf="errorMessage !=''" nzMessage="{{errorMessage}}" nzDescription="Department Already Exists. Please Try with some other information" nzShowIcon> </nz-alert>
            <div nz-row [nzGutter]="24">
                <div nz-col class="gutter-row" [nzSpan]="8">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzJustify="left" nzRequired>Department Name</nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Enter Department Name">
                            <input nz-input formControlName="dept_name" id="dept_name" maxlength="255" autocomplete="off"/>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="8">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzJustify="left" nzRequired>Department Description</nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Enter Department Description">
                            <input nz-input formControlName="dept_description" id="dept_description" maxlength="255"  autocomplete="off"/>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div *nzModalFooter>
                <div nz-row>
                    <div nz-col nzSpan="8" class="text-left">
                        <button *ngIf="id && !isActivate" (click)="activateDeActivateDepartment(departmentForm, 'deactivate')"  nz-button class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>De-activate</button>
                        <button *ngIf="id && isActivate" (click)="activateDeActivateDepartment(departmentForm, 'activate')"  nz-button class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>Activate</button>
                    </div>
                    <div nz-col nzSpan="12" nzOffset="4" class="text-right">
                        <button (click)="cancel()" nz-button class="ml-10" nzType="default"><i nz-icon nzType="close"  nzTheme="outline"></i>Cancel</button>
                        <button *ngIf="!id" (click)="SubmitButton(departmentForm , 'save')" nz-button class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>Submit</button>
                        <button *ngIf="id" (click)="SubmitButton(departmentForm, 'update')" nz-button [disabled]="(departmentForm.value.dept_name == originalValues?.dept_name && departmentForm.value.dept_description == originalValues?.dept_description)" class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>Update</button>
                    
                       
                    </div>
                    
                </div>
               
              </div>
        </form>
</div>

<nz-modal [(nzVisible)]="isSignatureModal" nzClassName="modal-no-footer" nzTitle="Electronic Signature"
    (nzOnCancel)="isSignatureModal=false;">
    <app-electronic-signature *ngIf="isSignatureModal" (AuthenticateEvent)="authenticateEvent($event)"  [auditObjectData]="auditObject" [deactivate]="isDeactivate"
        (SignaturepopupClose)="closeSignatureModal($event)"></app-electronic-signature>
</nz-modal>

<app-administration-table *ngIf="closeDepartment == true" [closeDepartment]="closeDepartment"></app-administration-table>
