<div nz-row>
    <div nz-col class="gutter-row" [nzSpan]="8">
        <nz-form-item>
            <nz-form-label [nzSpan]="24">Select Role</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <nz-select [nzShowSearch]="true" nzAllowClear nzPlaceHolder="Choose User Role" [(ngModel)]="selectedRoleId" (ngModelChange)="getCaseFormFields()">
                    <nz-option *ngFor="let role of userRoles" [nzValue]="role?.id" [nzLabel]="role.name">
                    </nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
    </div>
</div>
<div nz-row nz-form [nzGutter]="16">
    <div nz-col class="gutter-row" [nzSpan]="16">
        <nz-form-item >
            <nz-form-label [nzSpan]="24">Required Controls</nz-form-label>
            <nz-checkbox-wrapper style="width: 100%;">
                <div nz-row>
                    <div class="checkbox-margin" *ngFor="let control of selectedcaseFormFieldInfo; let i = index" nz-col nzSpan="24">
                        <span (ngModelChange)="onModalChange($event, i, 1)" [ngModel]="control?.isRequired == 1 ? true: false">{{control?.fieldLabel}}</span>
                    </div>
                </div>
            </nz-checkbox-wrapper>
        </nz-form-item>
    </div>
    <div nz-col class="gutter-row" [nzSpan]="4">
        <nz-form-item class="text-center">
            <nz-form-label [nzSpan]="24">Required Controls</nz-form-label>
            <nz-checkbox-wrapper style="width: 100%;">
                <div nz-row>
                    <div class="checkbox-margin text-center" *ngFor="let control of selectedcaseFormFieldInfo; let i = index" nz-col nzSpan="24">
                        <label nz-checkbox [nzValue]="control?.id" (ngModelChange)="onModalChange($event, i, 1)" [ngModel]="control?.isRequired == 1 ? true: false"></label>
                    </div>
                </div>
            </nz-checkbox-wrapper>
        </nz-form-item>
    </div>
    <div nz-col class="gutter-row" [nzSpan]="4">
        <nz-form-item class="text-center">
            <nz-form-label [nzSpan]="24">Editable Controls</nz-form-label>
            <nz-checkbox-wrapper style="width: 100%;">
                <div nz-row>
                    <div class="checkbox-margin text-center" *ngFor="let control of selectedcaseFormFieldInfo; let i = index" nz-col nzSpan="24">
                        <label nz-checkbox [nzValue]="control?.id" (ngModelChange)="onModalChange($event, i, 2)" [ngModel]="control?.isEditable == 1 ? true: false"></label>
                    </div>
                </div>
            </nz-checkbox-wrapper>
        </nz-form-item>
    </div>
</div>
<div nz-row nzJustify="end">
    <div nz-col>
        <button nz-button nzType="default" (click)="cancel()">Cancel</button>
        <button nz-button nzType="primary" class="p-0" (click)="updateFormControls()">
            <span *ngIf="!isLoading" class="px-10">Submit</span> 
            <ng-container  *ngIf="isLoading">
                <sb-button-loader *ngIf="getLoaderMessage()"></sb-button-loader>
                </ng-container>
        </button>
       
    </div>
</div>



<nz-modal [(nzVisible)]="isSignatureModal" nzClassName="modal-no-footer" nzTitle="Electronic Signature"
    (nzOnCancel)="isSignatureModal=false;">
    <app-electronic-signature *ngIf="isSignatureModal" (AuthenticateEvent)="authenticateEvent($event)" [auditObjectData]="auditObject" 
        (SignaturepopupClose)="closeSignatureModal($event)"></app-electronic-signature>
</nz-modal>