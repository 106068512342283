<div class="px-8">
  
    <form nz-form [formGroup]="conditionsForm" class="conditionsForm py-20 text-left">
        <nz-alert nzType="error" class="pb-20" *ngIf="errorMessage !=''" nzMessage="{{errorMessage}}" nzDescription="Condition Already Exists. Please Try with some other information" nzShowIcon> </nz-alert>
        <div nz-row [nzGutter]="24">
            <div nz-col class="gutter-row" [nzSpan]="8">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzJustify="left" nzRequired>Condition Name</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Condition Name">
                        <input nz-input formControlName="condition_name" id="condition_name" maxlength="255" autocomplete="off"/>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="8">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzJustify="left" nzRequired>Condition Description</nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Condition Description">
                        <input nz-input formControlName="description" id="description" maxlength="255"  autocomplete="off"/>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div *nzModalFooter>
            <div nz-row>
                <div nz-col nzSpan="8" class="text-left">
                    <button *ngIf="id && !isActivate" (click)="activateDeActivateCondition(conditionsForm, 'deactivate')"  nz-button class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>De-activate</button>
                    <button *ngIf="id && isActivate" (click)="activateDeActivateCondition(conditionsForm, 'activate')"  nz-button class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>Activate</button>
                </div>
                <div nz-col nzSpan="12" nzOffset="4" class="text-right">
                    <button (click)="cancel()" nz-button class="ml-10" nzType="default"><i nz-icon nzType="close"  nzTheme="outline"></i>Cancel</button>
                    <button *ngIf="!id" (click)="SubmitButton(conditionsForm , 'save')" nz-button class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>Submit</button>
                    <button *ngIf="id" (click)="SubmitButton(conditionsForm, 'update')" nz-button [disabled]="(conditionsForm.value.condition_name == originalValues?.condition_name && conditionsForm.value.description == originalValues?.description)" class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>Update</button>
                
                   
                </div>
                
            </div>
           
          </div>
    </form>
</div>

<nz-modal [(nzVisible)]="isSignatureModal" nzClassName="modal-no-footer" nzTitle="Electronic Signature"
(nzOnCancel)="isSignatureModal=false;">
<app-electronic-signature *ngIf="isSignatureModal" (AuthenticateEvent)="authenticateEvent($event)"  [auditObjectData]="auditObject" [deactivate]="isDeactivate"
    (SignaturepopupClose)="closeSignatureModal($event)"></app-electronic-signature>
</nz-modal>

<app-administration-table *ngIf="closeCondition == true" [closeCondition]="closeCondition"></app-administration-table>
