<!-- <div class="main-module">
    <h3 class="pt-3">Please Select A Module to Proceed</h3>
 
    <div nz-row class="cards cards-modules">
        <div nz-col  nzXs="24" nzSm="12" nzMd="6" *ngFor="let data of moduleNames">
            <nz-card class="content" nzTitle="Card title">
                <button class="modules-btn" nz-button nzType="primary" (click)="moduleSelection(data)">{{data.moduleName}} </button>
            </nz-card>
        </div>
    </div>
</div> -->
<div class="p-50 banner-background-modules">
    <app-user-logout></app-user-logout>
    <div nz-row class="main-module">
        <div nz-col [nzSpan]="24">
            <!-- <img *ngIf="logoValue == true" class="logo-size" [src]="logo" alt="Company Logo"> <br> -->
            <img class="logo-size" src="/../assets/images/logo.png" alt="No Company Logo Added"> <br>
          <sb-text [type]="'headline'">
            Pharmacovigilance
          </sb-text>
          <h2>Case Management System Modules</h2>
          <div class="moduleslist">
            <div  nz-row >
                <div nz-col nzSpan="8" nzOffset="8" class="module-names" *ngFor="let data of moduleNames">
                    <nz-card class="mb-20">
                        <div nz-row nzJustify="start">
                            <div nz-col nzSpan="20" class="pl-5">
                                <sb-text [type]="'subhead'">
                                    {{data.moduleName}}
                                </sb-text>
                                <sb-text *ngIf="data.moduleName == 'Case Intake Module'" [type]="'caption1'">
                                    Where User can create a case and view cases.
                                </sb-text>
                                <sb-text *ngIf="data.moduleName == 'Medical Enquiry Module'" [type]="'caption1'">
                                    Where User can view medical enquiry module related things.
                                </sb-text>
                                <sb-text *ngIf="data.moduleName == 'Administration'" [type]="'caption1'">
                                    Where superAdmin manages all the Administration Activities.
                                </sb-text>
                                <sb-text *ngIf="data.moduleName == 'Literature Monitoring'" [type]="'caption1'">
                                    Where User can see Literature Monitoring Related Things.
                                </sb-text>

                            </div>
                            <div nz-col nzSpan="4" class="text-right">
                                <a (click)="moduleSelection(data)">
                                    <i class="mt-15 fs-25" nz-icon nzType="arrow-right" nzTheme="outline"></i>
                                </a>
                            </div>
                        </div>
                    
                    
                </nz-card>
                <nz-empty nzNotFoundImage="assets/empty-img-gray.svg" [nzNotFoundContent]="contentTpl" *ngIf="data.length == 0">
                    <ng-template #contentTpl>
                        <sb-text [type]="'base text'">
                           Looks like no modules assigned. Please contact administrator
                        </sb-text>
                    </ng-template>
                </nz-empty>
                </div> <br>
                
              </div>
          </div>
         
              
            
         
        </div>
    </div>
</div>

