<nz-layout class="layout"  id="non-printable">
  <nz-sider class="sidebar border-shadow">
    <div class="logo">
      <div class="">
        <!-- <img *ngIf="logoValue == true && logo" class="logo-size pb-15" [src]="logo" alt="Company Logo"> <br> -->
        <img class="logo-size pb-15" src="/../assets/images/logo11.png" alt="No Company Logo Added to this account"> <br>
        <h1>Stability Planner</h1>
      </div>
      
      <!-- <form nz-form [formGroup]="moduleForm">
        <select formControlName="moduleName" class="w-100 py-6 px-30 select-module" name="moduleName" (change)="onModulechange()">
          <option *ngFor="let data of moduleNames" value="{{data.id}}">{{data.moduleName}}</option>
        </select>
      </form> -->
      <h2>Administration</h2>
    </div>
    <div class="logo1">
      <app-user-logout></app-user-logout>
    </div>
    <ul nz-menu nzMode="inline">
      <!-- <li *ngFor="let menu of menuItemss" >
        <ul >
          <li [nzSelected]="selectedMenuItem == menu.mainMenu" nz-menu-item nzTitle="{{menu.mainMenu}}" nzIcon="{{menu.icon}}"
          (click)="navigateMainRoute(menu)" routerLink="{{menu.route}}" routerLinkActive="active" class="submenulist">
          <i nz-icon nzType="{{menu.icon}}" nzTheme="outline"></i>
          <span>{{menu.mainMenu}}</span>
          </li>
        </ul>
      </li> -->
      <li *ngFor="let menu of menuItemss" >
        <ul *ngIf="menu.subMenuItems" nz-submenu [nzOpen]="selectedMenuItem == menu.mainMenu" nzTitle="{{menu.mainMenu}}" nzIcon="{{menu.icon}}"
          (click)="navigateMainRoute(menu)" class="submenulist" >
          <li [nzSelected]="selectedSubMenuItem == subMenu.subMenuTitle" *ngFor="let subMenu of menu.subMenuItems" nz-menu-item nzTitle="{{subMenu.subMenuTitle}}" nzIcon="{{subMenu.icon}}"
              (click)="navigateMainRoute(subMenu)">
              <i nz-icon nzType="{{subMenu.icon}}" nzTheme="outline"></i>
              <span>{{subMenu.subMenuTitle}}</span>
          </li>
        </ul>
        <ul *ngIf="!menu.subMenuItems">
          <li [nzSelected]="selectedMenuItem == menu.mainMenu" nz-menu-item nzTitle="{{menu.mainMenu}}" nzIcon="{{menu.icon}}"
          (click)="navigateMainRoute(menu)" routerLink="{{menu.route}}" routerLinkActive="active" class="submenulist">
          <i nz-icon nzType="{{menu.icon}}" nzTheme="outline"></i>
          <span>{{menu.mainMenu}}</span>
          </li>
        </ul>
      </li>

    </ul>
  </nz-sider>
  <!-- <nz-layout class="right-layout main-content"> -->
  <nz-content class="main-cantent-block">
    <div class="inner-content">
      <router-outlet></router-outlet>
    </div>
  </nz-content>
  <!-- </nz-layout> -->
</nz-layout>