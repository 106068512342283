import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DepartmentService } from '../department.service';

@Component({
  selector: 'app-department-form',
  templateUrl: './department-form.component.html',
  styleUrls: ['./department-form.component.less']
})
export class DepartmentFormComponent implements OnInit {

  departmentForm: any = FormGroup;
  isSignatureModal = false;
  auditObject: any = {}
  id: any;
  isDeactivate: boolean = false;
  closeDepartment: boolean = false;
  isActivate: boolean = false;
  deptStatus: any;
  errorMessage: string = "";
  originalValues: any;

  constructor(private fb: FormBuilder, private departmentService: DepartmentService, private router: Router, private messagService: AlertService,
    private readonly activedRoute: ActivatedRoute, private readonly modalservice: NzModalService, private readonly authservice: AuthService, private readonly commonservice : CommonService) {
    this.id = this.activedRoute.snapshot.params['departmentId'];
  }

  ngOnInit(): void {
    this.getDepartmentForm();
    if (this.id) {
      this.getDepartmentById();
    }
  }
  getDepartmentById() {
    this.departmentService.getDepartmentDataById(this.id).subscribe((res: any) => {
      if(res.active == 0) {
        this.isActivate = true;
      }; 
      this.deptStatus = res.active;
      this.originalValues = res;
      this.departmentForm.patchValue(res);
    }, error => {
      console.log(error);
    });
  }
  getDepartmentForm() {
    this.departmentForm = this.fb.group({
      id: [''],
      dept_name: ['', [Validators.required]],
      dept_description: ['', [Validators.required]]
    })
  }


  cancel() {
    // this.router.navigateByUrl('/administration/masters/department')
    this.closePopup();
  }

  SubmitButton(data: any, type?: any) {
    if(type == 'save') {
      this.postDepartment(data,type)
    }
    else {
      this.updateDepartment(data,type)
    }
  }


  postDepartment(data: any, type: any) {
    for (const i in data.controls) {
      data.controls[i].markAsDirty();
      data.controls[i].updateValueAndValidity();
    }
    if (data.valid) {
      if (type == 'deactivate') {
        this.isDeactivate = true;
      } else {
        this.isDeactivate = false;
      }
      this.isSignatureModal = true;
    }
  }

  updateDepartment(data: any, type: any) {
    for (const i in data.controls) {
      data.controls[i].markAsDirty();
      data.controls[i].updateValueAndValidity();
    }
    if (data.valid) {
      if (type == 'deactivate') {
        this.isDeactivate = true;
      } else {
        this.isDeactivate = false;
      }
      if(this.originalValues.dept_name!=this.departmentForm.value.dept_name || this.originalValues.dept_description!=this.departmentForm.value.dept_description) {
        this.isSignatureModal = true;
      } 
    }
  }
 
  authenticateEvent(event: any) {
    if (!this.id) {
      const departmentname = this.departmentForm.controls.dept_name.value;
      this.departmentService.postDepartment(this.departmentForm.value).subscribe((res: any) => {
        if(res.message == 'Department Already Exists') {
          this.errorMessage = res.message;
          setTimeout(() => {
            this.errorMessage = '';
          }, 3000);
        }
        else {
          this.auditObject = this.commonservice.saveAuditTrialsObject(` Created Department "${departmentname}" with Department Description "${this.departmentForm.value.dept_description}"`, event.remarks, `Department Audit Trails`);
          this.messagService.alertMessage("success", "Success", "Department Added Successfully");
          this.closePopup();
        }  
      }, error => {
        console.log('error');

      });
      this.isSignatureModal = false;
    } 
    else {
      if (this.isDeactivate) {
        this.deActivateDepartment(event);
      } else {
        const departmentname = this.departmentForm.controls.dept_name.value;
        let changedValue: any = [];
        let body = {
          values:this.departmentForm.value,
          originalValues: this.originalValues 
        }
        if(this.departmentForm.value!=this.originalValues) {
          if(this.originalValues.dept_name!=this.departmentForm.value.dept_name) {
            changedValue.push(`Department Name from '${this.originalValues.dept_name}' to '${this.departmentForm.value.dept_name}'`)
          }
          if(this.originalValues.dept_description!=this.departmentForm.value.dept_description) {
            changedValue.push(`Department Description from '${this.originalValues.dept_description}' to '${this.departmentForm.value.dept_description}'`)
                this.errorMessage = '';
          }
          this.departmentService.updateDepartment(body).subscribe((res: any) => {
            if(res.message == 'Duplicate Department') {
              this.errorMessage = res.message;
              setTimeout(() => {
                this.errorMessage = '';
              }, 3000);
            }
            else {
              this.auditObject = this.commonservice.saveAuditTrialsObject(`changed "${changedValue.toString()}"`, event.remarks, `Department Audit Trails`);

              this.messagService.alertMessage("success", "Success", "Department Updated Successfully");
              this.closePopup();
            }  
          }, error => {
            console.log('error');

          });
          this.isSignatureModal = false;
        }  
      }
    }
    setTimeout(() => {
      // this.router.navigateByUrl('/administration/masters/department');
 
    }, 4000);
  }

  deActivateDepartment(event: any) {
    const departmentname = this.departmentForm.controls.dept_name.value;
    let activateName: any;
    if(this.deptStatus == 1) {
      activateName = 'DeActivated'
    }
    else if(this.deptStatus == 0) {
      activateName = 'Activated'
    }
    this.departmentService.activateDeActivateDepartment(this.id, this.deptStatus, this.departmentForm.value).subscribe((res: any) => {
      this.auditObject = this.commonservice.saveAuditTrialsObject(` ${activateName} The Department "${departmentname}" `, event.remarks, `Department Audit Trails`);
      this.messagService.alertMessage("success", "Success", `Department ${activateName} Successfully`);
      this.closePopup();

    }, error => {
      console.log('error');

    });
    this.isSignatureModal = false;
    setTimeout(() => {
      // this.router.navigateByUrl('/administration/masters/department');
 
    }, 4000);
  }
  closeSignatureModal(event: any) {
    this.isSignatureModal = event;
  }
  closePopup() {
    this.modalservice.closeAll();
    this.closeDepartment = true;
    this.commonservice.getApisData(true);
  }

  activateDeActivateDepartment(data: any, type: any) {
    for (const i in data.controls) {
      data.controls[i].markAsDirty();
      data.controls[i].updateValueAndValidity();
    }
    if (data.valid) {
      this.isDeactivate = true;
      this.isSignatureModal = true;
    }
  }
}