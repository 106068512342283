import { Component, OnInit } from '@angular/core';
import { count } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.less']
})
export class TilesComponent implements OnInit {
  sourceList: any;
  selectedName: any;
  casesCount: any;
  classificationData: any = [{ "name": "MI", "id": 0 }, { "name": "AE", "id": 1 }, { "name": "PQC", "id": 2 }];
  selectedClassification: any;
  sourceListTotal: any;
  userData: any;

  constructor(private commonService: CommonService,private authService: AuthService) { 
    this.userData=this.authService.getUserData();
  }

  ngOnInit(): void {
    this.getCasesCounts()
  }
  
  getCasesCounts() {
    this.commonService.getCasesCounts(this.userData.recieveCaseStatus, this.userData.id).subscribe((res: any) => {
      this.sourceList=res.sourcesData;
      this.selectedName = this.sourceList[0].name;
      let allCount = this.sourceList.map((x: any) => x.count);
      this.sourceList.map((a:any) => {
        if(a.id==null){
          this.sourceListTotal=a.count
        }
      });
      this.classificationData=res.classificationsData
    }, error => {
      console.log(error)
    })
  }
  selectedSource(data: any) {
    this.selectedName = data.name;
    // this.selectedSourceId = data.id;
    // this.getCasesList();
  }

  selectedData(data: { id: number, name: string }) {
    this.selectedClassification = data.name;
    // this.selectedClassificationId = data.id
    // this.getCasesByClassification();
  }

}
