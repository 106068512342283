
<ul nz-menu nzMode="horizontal" class="menubar">
    <li nz-menu-item  class="mr-5">
        <strong class="fs-20">
             <img *ngIf="logoValue == true" src="{{logo}}" style="width:40px;height: 40px;" class="logo-png" alt="Company Logo">
             <img *ngIf="logoValue == false" style="width:40px;height: 40px;" class="logo-png" alt="No Company Logo Added">
             <span class="ml-2"> {{moduleData}} </span>
        </strong>
    </li>
    <!-- <li nz-menu-item [routerLink]="['/dashboard/initiator']" class="ml-5" nzSelected>
        Dashboard
    </li>
    <li nz-menu-item [routerLink]="['/case-management-system/activity-logs']">
        <span>Activity Logs</span>
    </li>
    <li nz-menu-item [routerLink]="['/case-management-system/reports']" class="mr-5">
        <span class="mr-4"> Reports</span>
    </li>
    <li nz-menu-item class="headerdrops ml-5">
        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <span>Case Intake</span>
            <i nz-icon nzType="down"></i>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item>1st menu item</li>
                <li nz-menu-item>2nd menu item</li>
            </ul>
        </nz-dropdown-menu>
    </li>
    <li nz-menu-item class="headerdrops" >
        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
           <span> All</span>
            <i nz-icon nzType="down"></i>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item>1st menu item</li>
                <li nz-menu-item>2nd menu item</li>
            </ul>
        </nz-dropdown-menu>
    </li>
    <li nz-menu-item class="headerdrops">
        <span>
            <input nz-input placeholder="Search" />
        </span>
        
    </li> -->
    <li  class=" userprofie-nav">
        <div class="d-flex align-items-center">
            <div class="d-flex flex-column text-right pr-3">
                <strong class="line-h-1 pb-1">{{UserData.username}}</strong>
                <span class="line-h-1">{{UserData.roleName}}</span>
            </div>
            <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menuprofile">
                <a class="logo-block"> <img src="../../../../assets/images/img_avatar.png" alt="logo" /> </a>
            </a>
        </div>
        <nz-dropdown-menu #menuprofile="nzDropdownMenu" >
            <ul nz-menu>
                <li nz-menu-item (click)="changePassword()" class="px-20">
                    <span class="pr-50">Change Password</span> <i nz-icon  nzTheme="outline"></i>
                 </li>
                <li nz-menu-item (click)="switchmodule()" class="px-20">
                   <span class="pr-50">Switch Module</span> <i nz-icon nzType="user-switch" nzTheme="outline"></i>
                </li>
                <li nz-menu-item (click)="logout()" class="px-20">
                   <span class="pr-50">Log Out </span> <i nz-icon nzType="logout" nzTheme="outline" class=" float-right"></i></li>
                <!-- <li nz-menu-item>2nd menu item</li> -->
            </ul>
        </nz-dropdown-menu>
    </li>
</ul>