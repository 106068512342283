<div nz-row>
    <div nz-col [nzSpan]="14" [nzOffset]="10" class="text-right mb-10">
        <div class="pagination">
            <button nz-button nzType="default" [disabled]="pageNo == 1" (click)="prevBtn()">
                <i nz-icon nzType="left" nzTheme="outline"></i>
            </button>
            <div class="page-count">Page {{pageNo | number}}
            </div>
            <button nz-button nzType="default" [disabled]="totalcount <= (pageNo*pageSize)" (click)="nextBtn()">
                <i nz-icon nzType="right" nzTheme="outline"></i>
            </button>
            <nz-select class="page-btn" [ngModel]="pageSize" (ngModelChange)="pagesizeChange($event)">
                <nz-option class="option" *ngFor="let item of pagescount" [nzValue]="item.pagecount" nzLabel="{{item.pagecount}} / Page"></nz-option>
            </nz-select>
        </div>
    </div>
</div>