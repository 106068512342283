import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { RouterModule } from '@angular/router';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
// import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { HeaderComponent } from './components/header/header.component';
import { TilesComponent } from './components/tiles/tiles.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonService } from '../core/services/common.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '../core/interceptor/jwt.interceptor';
import { CaseHandlersComponent } from './components/case-handlers/case-handlers.component';
import { MailComponent } from './components/mail/mail.component';
import { ModuleSectionComponent } from './components/module-section/module-section.component';
import { CaseService } from './services/cases.service';
import { AlertComponent } from './components/alert/alert.component';
import { MailService } from './services/mail.service';
import { HasAuthorityDirective } from './directives/has-authority.directive';
import { PermissonsService } from '../core/services/permissions.service';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { ElectronicSignatureComponent } from './components/electronic-signature/electronic-signature.component';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzListModule } from 'ng-zorro-antd/list';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderInterceptor } from '../core/interceptor/loader.interceptor';
import { LoaderService } from '../core/services/loader.service';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { LayoutComponent } from './components/layout/layout.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AdministrationTableComponent } from './components/administration-table/administration-table.component';
import { AlertService } from './services/alert.service';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { ChartsModule } from 'ng2-charts';
import { NzCardModule } from 'ng-zorro-antd/card';
import { UserLogoutComponent } from './components/user-logout/user-logout.component';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { TextComponent } from './components/text/text.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { ManageFormComponent } from './components/manage-form/manage-form.component';
import { ButtonLoaderComponent } from './components/button-loader/button-loader.component';
import { PdfFileViewerComponent } from './components/pdf-file-viewer/pdf-file-viewer.component';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';

const antDesignIcons = AllIcons as {
  [key: string]: any;
};
const icons: any = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

@NgModule({
  declarations: [
    HeaderComponent,
    TilesComponent,
    CaseHandlersComponent,
    MailComponent,
    ModuleSectionComponent,
    AlertComponent,
    HasAuthorityDirective,
    PaginationComponent,
    ElectronicSignatureComponent,
    BreadcrumbComponent,
    LoaderComponent,
    SideNavComponent,
    LayoutComponent,
    AdministrationTableComponent,
    StatisticsComponent,
    UserLogoutComponent,
    TextComponent,
    ManageFormComponent,
    ButtonLoaderComponent,
    PdfFileViewerComponent
  ],

  imports: [
    CommonModule,
    HttpClientModule,
    NzGridModule,
    NzLayoutModule,
    NzLayoutModule,
    NzBreadCrumbModule,
    NzMenuModule,
    NzIconModule,
    ReactiveFormsModule,
    FormsModule,
    NzGridModule,
    RouterModule,
    NzDividerModule,
    NzPageHeaderModule,
    NzTabsModule,
    NzButtonModule,
    NzTableModule,
    NzSwitchModule,
    NzDropDownModule,
    NzFormModule,
    NzToolTipModule,
    NzUploadModule,
    NzDrawerModule,
    NzDescriptionsModule,
    NzBadgeModule,
    NzModalModule,
    NzStepsModule,
    NzInputModule,
    NzDatePickerModule,
    NzSpaceModule,
    NzCheckboxModule,
    NzTypographyModule,
    NzSelectModule,
    NzRadioModule,
    NzEmptyModule,
    NzCollapseModule,
    NzListModule,
    ChartsModule,
    NzCardModule,
    ReactiveFormsModule,
    NzAvatarModule,
    NzAlertModule,
    NzMessageModule,
    NzModalModule,
    NzPaginationModule,
    NzPopoverModule,
    NzAutocompleteModule
  ],

  exports: [
    NzLayoutModule,
    NzBreadCrumbModule,
    NzMenuModule,
    NzIconModule,
    NzGridModule,
    NzFormModule,
    RouterModule,
    NzPageHeaderModule,
    NzTabsModule,
    NzButtonModule,
    NzTableModule,
    NzSwitchModule,
    NzDropDownModule,
    NzDividerModule,
    NzToolTipModule,
    NzUploadModule,
    NzDrawerModule,
    NzBadgeModule,
    NzDescriptionsModule,
    NzStepsModule,
    NzInputModule,
    NzDatePickerModule,
    NzSpaceModule,
    NzSelectModule,
    NzTypographyModule,
    NzCheckboxModule,
    NzRadioModule,
    NzCollapseModule,
    NzListModule,
    NzGridModule,
    MailComponent,
    CaseHandlersComponent,
    AlertComponent,
    HasAuthorityDirective,
    PaginationComponent,
    ElectronicSignatureComponent,
    LoaderComponent,
    SideNavComponent,
    LayoutComponent,
    AdministrationTableComponent,
    StatisticsComponent,
    ManageFormComponent,
    ChartsModule,
    NzModalModule,
    NzFormModule,
    ReactiveFormsModule,
    NzEmptyModule,
    NzAvatarModule,
    NzMessageModule,
    TextComponent,
    NzPopoverModule,
    ButtonLoaderComponent,
    NzPaginationModule,
  ],
  providers: [CommonService, MailService, CaseService, AlertService, LoaderService,{ provide: NZ_ICONS, useValue: icons },
    PermissonsService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    NzNotificationService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class SharedModule { }
