<form nz-form [formGroup]="validateForm">
  <nz-layout class="no-bg">
    <nz-content>
      <nz-breadcrumb>
        <nz-breadcrumb-item></nz-breadcrumb-item>
      </nz-breadcrumb>
      <div>
        <nz-form-item>
          <nz-form-control [nzErrorTip]="combineTpl1">
            <nz-input-group nzSize="large" nzPrefix="To:">
              <input nzRequired formControlName="to" id="to" nz-input placeholder="Emails comma separated" maxlength="100"/>
              <ng-template #combineTpl1 let-control>
                <ng-container *ngIf="control.hasError('pattern')">Please Enter Valid
                  Email</ng-container>
                <ng-container *ngIf="control.hasError('required')">Please Enter
                  Email</ng-container>
              </ng-template>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div>
        <nz-form-control nzErrorTip="Please Enter Valid Email">
          <nz-input-group nzSize="large" nzPrefix="Cc:">
            <input formControlName="cc" id="cc" nz-input placeholder="Emails comma separated" maxlength="100"/>
          </nz-input-group>
        </nz-form-control>
      </div><br>
      <div>
        <nz-form-control nzErrorTip="Please write something here!">
          <nz-input-group nzSize="large">
            <input type="text" formControlName="subject" nzRequired nz-input placeholder="Add a subject" maxlength="200"/>
          </nz-input-group>
        </nz-form-control>
      </div><br>
      <div>
        <div #bodyTemplate class="mail-template" contenteditable="true" (input)="onMailBodyInput($event)"></div>
      </div>

      <div nz-row>
        <button nz-button class="px-10" *ngIf="!isLoading" nzType="primary" [disabled]="!validateForm.valid"
          (click)="sendFollowupData(validateForm)">
          <ng-container> Send</ng-container>

        </button>
        <span *ngIf="isLoading" class=" mt-32">
          <sb-button-loader class="w-100" *ngIf="getLoaderMessage()"></sb-button-loader>
        </span>
        <button nz-button nzType="default" (click)="closeModal()"><i nz-icon nzType="delete"
            nzTheme="outline"></i>Discard</button>
        <!-- *ngIf="mailpopupdata?.type == 'casefollowUp' || mailpopupdata?.type == 'respondToReporter' || mailpopupdata?.type == 'caseInvestigationReport'" -->
        <span>
          <nz-upload *ngIf="isFileAttached" [nzHeaders]="{ authorization: 'authorization-text' }"
            [(nzFileList)]="sourceDocfileList" [nzShowUploadList]="nzShowUploadList"
            [nzMultiple]="true" (nzChange)="uplaodMailAttachment()">
            <button nz-button>
              <i nz-icon nzType="paper-clip"></i>Attach
            </button>
          </nz-upload>
        </span>

      </div>

      <nz-list *ngIf="rerpondToReporter.length > 0" class="text-left" nzItemLayout="horizontal">
        <nz-list-item *ngFor="let item of rerpondToReporter" style="display: block;">
          <p>To: {{item.followupRequestedTo}}</p>
          <p>Subject: {{item.followupSubject}}</p>
          <span>Description: <p [innerHTML]="item.followupMessage"></p></span>
          <a *ngIf="item.followupFile != null" href="javascript:;" (click)="downloadDoc(item.followupFile)"><i nz-icon
              nzType="paper-clip" nzTheme="outline"></i>
            &nbsp;<span class="fs-10">{{item.followupFile}}</span>
          </a>
        </nz-list-item>
      </nz-list>

    </nz-content>

  </nz-layout>

</form>


<nz-modal [(nzVisible)]="isSignatureModal" nzClassName="modal-no-footer" nzTitle="Electronic Signature"
  (nzOnCancel)="isSignatureModal=false;">
  <app-electronic-signature *ngIf="isSignatureModal" (AuthenticateEvent)="authenticateEvent($event)" [auditObjectData]="auditObject"
    (SignaturepopupClose)="closeSignatureModal($event)"></app-electronic-signature>
</nz-modal>
<!-- <nz-modal [(nzVisible)]="showSuccess" (nzOnCancel)="showSuccess = !showSuccess" nzClassName="modal-no-footer">
  <app-alert [message]="'Mail Sent Successfully'"></app-alert>
</nz-modal> -->