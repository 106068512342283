import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ParametersService } from '../parameters.service';

@Component({
  selector: 'sb-parameters-form',
  templateUrl: './parameters-form.component.html',
  styleUrls: ['./parameters-form.component.less']
})
export class ParametersFormComponent implements OnInit {
  parameterForm: any = FormGroup;
  isSignatureModal = false;
  auditObject: any = {}
  id: any;
  isDeactivate: boolean = false;
  closeParameter: boolean = false;
  isActivate: boolean = false;
  parameterStatus : any;
  errorMessage: string = "";
  originalValues: any;

  constructor(private fb: FormBuilder, private parameterService: ParametersService, private router: Router, private messagService: AlertService,
    private readonly activedRoute: ActivatedRoute, private readonly modalservice: NzModalService, private readonly authservice: AuthService, private readonly commonservice : CommonService) {
    this.id = this.activedRoute.snapshot.params['parameterId'];
  }

  ngOnInit(): void {
    this.getparameterForm();
    if (this.id) {
      this.getParameterById();
    }
  }
  getParameterById() {
    this.parameterService.getParameterById(this.id).subscribe((res: any) => {
      if(res.active == 0) {
        this.isActivate = true;
      }; 
      this.parameterStatus = res.active;
      this.originalValues = res;
      this.parameterForm .patchValue(res);
    }, error => {
      console.log(error);
    });
  }
  getparameterForm() {
    this.parameterForm = this.fb.group({
      id: [''],
      parameter_name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      device:['',[Validators.required]]
    })
  }


  cancel() {
    // this.router.navigateByUrl('/administration/masters/department')
    this.closePopup();
  }

  SubmitButton(data: any, type?: any) {
    if(type == 'save') {
      this.postParameter(data,type)
    }
    else {
      this.updateParameter(data,type)
    }
  }


  postParameter(data: any, type: any) {
    for (const i in data.controls) {
      data.controls[i].markAsDirty();
      data.controls[i].updateValueAndValidity();
    }
    if (data.valid) {
      if (type == 'deactivate') {
        this.isDeactivate = true;
      } else {
        this.isDeactivate = false;
      }
      this.isSignatureModal = true;
    }
  }

  updateParameter(data: any, type: any) {
    for (const i in data.controls) {
      data.controls[i].markAsDirty();
      data.controls[i].updateValueAndValidity();
    }
    if (data.valid) {
      if (type == 'deactivate') {
        this.isDeactivate = true;
      } else {
        this.isDeactivate = false;
      }
      if(this.originalValues.parameter_name!=this.parameterForm.value.parameter_name || this.originalValues.description!=this.parameterForm.value.description) {
        this.isSignatureModal = true;
      } 
    }
  }
 
  authenticateEvent(event: any) {
    if (!this.id) {
      const parametername = this.parameterForm.controls.parameter_name.value;
      this.parameterService.postParameter(this.parameterForm.value).subscribe((res: any) => {
        if(res.message == 'parameter already exist') {
          this.errorMessage = res.message;
          setTimeout(() => {
            this.errorMessage = '';
          }, 3000);
        }
        else {
          this.auditObject = this.commonservice.saveAuditTrialsObject(` Created Parameter "${parametername}" with Parameter Description "${this.parameterForm.value.description}" and "${this.parameterForm.value.device}" device`, event.remarks, `Parameter Audit Trails`);
          this.messagService.alertMessage("success", "Success", "Parameter Added Successfully");
          this.closePopup();
        }  
      }, error => {
        console.log('error');

      });
      this.isSignatureModal = false;
    } 
    else {
      if (this.isDeactivate) {
        this.deActivateParameter(event);
      } else {
        const parametername = this.parameterForm.controls.parameter_name.value;
        let changedValue: any = [];
        let body = {
          values:this.parameterForm.value,
          originalValues: this.originalValues 
        }
        if(this.parameterForm.value!=this.originalValues) {
          if(this.originalValues.parameter_name!=this.parameterForm.value.parameter_name) {
            changedValue.push(`Parameter Name from '${this.originalValues.parameter_name}' to '${this.parameterForm.value.parameter_name}'`)
          }
          if(this.originalValues.description!=this.parameterForm.value.description) {
            changedValue.push(`Parameter Description from '${this.originalValues.description}' to '${this.parameterForm.value.description}'`)
          }
          this.parameterService.updateParameter(this.parameterForm.value).subscribe((res: any) => {
            if(res.message == 'Parameter already exist') {
              this.errorMessage = res.message;
              setTimeout(() => {
                this.errorMessage = '';
              }, 3000);
            }
            else {
              this.auditObject = this.commonservice.saveAuditTrialsObject(`changed "${changedValue.toString()}" for the '${this.parameterForm.value.device}' device`, event.remarks, `Parameter Audit Trails`);

              this.messagService.alertMessage("success", "Success", "Parameter Updated Successfully");
              this.closePopup();
            }  
          }, error => {
            console.log('error');

          });
          this.isSignatureModal = false;
        }  
      }
    }
    setTimeout(() => {
      // this.router.navigateByUrl('/administration/masters/department');
 
    }, 4000);
  }

  deActivateParameter(event: any) {
    const parametername = this.parameterForm.controls.parameter_name.value;
    let activateName: any;
    if(this.parameterStatus == 1) {
      activateName = 'DeActivated'
    }
    else if(this.parameterStatus  == 0) {
      activateName = 'Activated'
    }
    let body = {
      id:this.id,
      active:activateName == 'Activated' ? 1 : 0
    }
    this.parameterService.updateParameter(body).subscribe((res: any) => {
      this.auditObject = this.commonservice.saveAuditTrialsObject(` ${activateName} The Parameter "${parametername}"  with "${this.parameterForm.value.device}" device`, event.remarks, `Parameter Audit Trails`);
      this.messagService.alertMessage("success", "Success", `Parameter ${activateName} Successfully`);
      this.closePopup();

    }, error => {
      console.log('error');

    });
    this.isSignatureModal = false;
    setTimeout(() => {
      // this.router.navigateByUrl('/administration/masters/department');
 
    }, 4000);
  }
  closeSignatureModal(event: any) {
    this.isSignatureModal = event;
  }
  closePopup() {
    this.modalservice.closeAll();
    this.closeParameter = true;
    this.commonservice.getApisData(true);
  }

  activateDeActivateDepartment(data: any, type: any) {
    for (const i in data.controls) {
      data.controls[i].markAsDirty();
      data.controls[i].updateValueAndValidity();
    }
    if (data.valid) {
      this.isDeactivate = true;
      this.isSignatureModal = true;
    }
  }
}
