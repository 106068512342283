import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.less']
})
export class PaginationComponent implements OnInit {

  @Output() onPageChangeEvent = new EventEmitter<number>();
  @Output() onPageSizeChangeEvent = new EventEmitter<number>();
  @Input('pageNo') pageNo: any;
  @Input('pageSize') pageSize: any;
  @Input('totalLength') totalLength: any;
  totalcount: number = 0;
  pagescount: any = [
    {
      pagecount: 10
    },
    {
      pagecount: 20
    },
    {
      pagecount: 30
    },
    {
      pagecount: 40
    },
    {
      pagecount: 50
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.totalLength) {
      this.totalcount = changes.totalLength.currentValue;
    }
  }

  nextBtn(){
    let showed = (this.pageNo)*this.pageSize;
    if(showed < this.totalcount){
      this.pageNo++;
      this.paginationChange();
    }
  }

  prevBtn(){
    // console.log("this.totalcount",this.totalcount)
    if(this.pageNo > 1){
      this.pageNo--;
      this.paginationChange();
    }
  }

  paginationChange(){
    this.onPageChangeEvent.emit(this.pageNo);
  }

  pagesizeChange(pagesize: any){
    this.pageSize = pagesize;
    this.pageNo = 1;
    this.onPageSizeChangeEvent.emit(this.pageSize);
  }

}
