import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuditTrailsService } from 'src/app/administration/audit-trails/audit-trails.service';
import { Audit, IAudit } from 'src/app/administration/audit-trails/audit.model';
import { CommonService } from 'src/app/core/services/common.service';
import { StorageService } from 'src/app/services/storage.service';
import { AlertService } from 'src/app/shared/services/alert.service';
@Component({
  selector: 'app-electronic-signature',
  templateUrl: './electronic-signature.component.html',
  styleUrls: ['./electronic-signature.component.less']
})
export class ElectronicSignatureComponent implements OnInit {
validateForm!:FormGroup;
@Output() SignaturepopupClose = new EventEmitter();
@Output() AuthenticateEvent = new EventEmitter<any>();
@Input() auditObject:any;
@Input() auditObjectData : any;
@Input() deactivate: any ;
audit!: IAudit;
auditData:any;
userData:any;
invalidPassword: boolean = false;
  isLoading: boolean = false;
  constructor(private fb:FormBuilder, private storageService:StorageService,  private auditService: AuditTrailsService, private readonly commonService: CommonService,private messageService:AlertService) { 
    this.audit = new Audit();
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userid: [this.audit.userId, [Validators.required]],
      password: [null, [Validators.required]],
      remarks: [null, [Validators.required, Validators.maxLength(100)]],
      deactivate : [this.deactivate]
    });
  }
  Authenticate(){
    this.invalidPassword = false
    if (!this.validateForm.valid) {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
      return;
    }
    let password = btoa(this.validateForm.value.password);
    const obj = {
      userId: this.validateForm.value.userid,
      password: password, 
      remarks:this.validateForm.value.remarks

    }
    if(!this.isLoading){
      this.validatioForPassword(obj);
    } this.isLoading = true;
  }
  validatioForPassword(obj: any){
    this.commonService.validatePassword(obj).subscribe((response: any) => {
      if(response.validUser){
        this.validateForm.controls.deactivate.setValue(this.deactivate);
        this.AuthenticateEvent.emit(this.validateForm.value);
        this.messageService.alertMessage("success", "Success", "Electronic Signature Added Successfully");
      }  else {
        this.invalidPassword = true;
        this.messageService.alertMessage("error", "Invalid Credentials", "Please Enter Valid Credentials");
      }
    }, error => {

    });
  }
  signatureCloseModal() {
    this.SignaturepopupClose.emit(false)
  }
  ngOnChanges(changes: SimpleChanges) {
    if(Array.isArray(this.auditObjectData)){
      for(let i in this.auditObjectData){
        this.commonData(this.auditObjectData[i]);
      }
    } else{
      if(Object.keys(this.auditObjectData).length != 0){
        this.commonData(this.auditObjectData);
      }
    } 
  }
  commonData(auditObjectData: any){
    if(auditObjectData.status == 'ACTIVITY_COMPLETED'){
      this.audit.activity = auditObjectData.activity;
      this.audit.remark = auditObjectData.remarks;
      this.audit.typeOfAudit = auditObjectData.typeOfAudit;
      this.audit.ipAddress = auditObjectData.ipAddress;
      this.postAuditTrails();
    }
  }

  postAuditTrails() {
    // this.auditService.postAuditTrails(this.audit).subscribe((res: any) => {
    //   console.log(res);
    //   this.validateForm.controls.remarks.reset()
    // }, (error:any) => {
    //   console.log(error);
    // })
    
  }
  getLoaderMessage(){
    if(this.storageService.getItem('loader')== 'true'){
      return true;
    }
    this.isLoading = false;
    return false;
  }
}
