<div>
  <div class="py-10 ">
    <div nz-row class="py-15 border mb-15 px-15 pt-25">
      <h3 nz-col nzSpan="6" class="user_reg text-muted text-left pl-10">Statistics</h3>

      <div nz-col nzSpan="5" nzOffset="8" class="px-20">
        <nz-select class="w-100" nzPlaceHolder="Select Graph Type" [(ngModel)]="optionValue">
          <nz-option nzValue="all" nzLabel="All"></nz-option>
          <nz-option nzValue="bar" nzLabel="bar"></nz-option>
          <nz-option nzValue="pie" nzLabel="Pie"></nz-option>
        </nz-select>
      </div>

      <div nz-col nzSpan="5">
        <nz-select class="w-100" ngModel="graphs" nzPlaceHolder="Select Status" [(ngModel)]="dataValue">
          <nz-option nzValue="all" nzLabel="All"></nz-option>
          <nz-option nzValue="sources" nzLabel="Sources"></nz-option>
          <nz-option nzValue="classification" nzLabel="Classification"></nz-option>

        </nz-select>
      </div>

    </div>

    <div nz-row>

      <div
        [hidden]="!((optionValue == 'all' || optionValue == 'pie') && (dataValue == 'all' || dataValue == 'classification'))"
        nz-col [nzSpan]="24" class="p-20 ">
        <div class="border">
          <div class=" px-24">
            <div class=" text-left fs-15  py-16" nz-typography="primary">Case Classification</div>
          </div>
          <div nz-col [nzSpan]="16" class="p-24">
            <div class="charts-body" id="container"></div>
          </div>
        </div>
      </div>

      <div
        [hidden]="!((optionValue == 'all' || optionValue == 'bar' ) && (dataValue == 'all' || dataValue == 'classification'))"
        nz-col [nzSpan]="24" class="p-20 ">
        <div class="border">
          <div class=" px-24">
            <div class="text-left fs-15 py-16">Case Classification Bar Chart</div>
          </div>
          <div nz-col [nzSpan]="12" class="p-24">
            <div class="charts-body" id="barchart"></div>
          </div>
        </div>
      </div>

    </div>

    <div nz-row>

      <div
        [hidden]="!((optionValue == 'all' || optionValue == 'pie') && (dataValue == 'all' || dataValue == 'sources'))"
        nz-col [nzSpan]="24" class="p-20 ">
        <div class="border">
          <div class=" px-24">
            <div class="text-left fs-15 py-16">Sources Data</div>
          </div>
          <div nz-col [nzSpan]="16" class="p-24">
            <div class="charts-body" id="sourcesData">
            </div>
          </div>
        </div>
      </div>

      <div
        [hidden]="!((optionValue == 'all' || optionValue == 'bar') && (dataValue == 'all' || dataValue == 'sources'))"
        nz-col [nzSpan]="24" class="p-20">
        <div class="border">
          <div class=" px-24">
            <div class=" text-left fs-15 py-16">Sources Bar Chart</div>
          </div>
          <div nz-col [nzSpan]="18" class="p-24">
            <div class="charts-body" id="Sourcesbarchart"></div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>