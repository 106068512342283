import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from './core/services/auth.service';
import { JwtInterceptor } from './core/interceptor/jwt.interceptor';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { SharedModule } from './shared/shared.module';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { SessionTimeoutComponent } from './shared/components/session-timeout/session-timeout.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { NzFormModule } from 'ng-zorro-antd/form';
import { Keepalive, NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { Idle, NgIdleModule } from '@ng-idle/core';
import { CookieModule } from 'ngx-cookie';
registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    SessionTimeoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzDropDownModule,
    NzDividerModule,
    NzGridModule,
    NzLayoutModule,
    NzCardModule,
    NzGridModule,
    NzSpaceModule,
    NzUploadModule,
    NzTabsModule,
    NzModalModule,
    NzRadioModule,
    NzCollapseModule,
    SharedModule,
    FormsModule,
    NzFormModule,
    NgIdleModule.forRoot(),
    CookieModule.forChild()
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US }, 
    AuthService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true
  },
  BnNgIdleService, Idle
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA], 
  bootstrap: [AppComponent]
})
export class AppModule { }
