<div class="px-8">
        <form nz-form [formGroup]="plantMasterForm" class="plantMasterForm py-20 text-left">
            <nz-alert nzType="error" class="pb-20" *ngIf="errorMessage !=''" nzMessage="{{errorMessage}}" nzDescription="Plant Already Exists. Please Try with some other information" nzShowIcon> </nz-alert>
            <div nz-row [nzGutter]="24">
                <div nz-col class="gutter-row" [nzSpan]="8">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzJustify="left" nzRequired>Plant Name</nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Please Enter Plant Name">
                            <input nz-input formControlName="plant_name" id="plant_ame" maxlength="255"  autocomplete="off"/>  
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col class="gutter-row" [nzSpan]="8">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzJustify="left" nzRequired>Plant Location</nz-form-label>
                        <nz-form-control [nzSpan]="24" nzErrorTip="Please Enter Plant Location">
                            <input nz-input formControlName="plant_location" id="plant_location" maxlength="255" autocomplete="off" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <div nz-row [nzGutter]="24">
                
            </div>

            <!-- <div nz-row nzJustify="center" class="formsubmitbtns">
                
                <button *ngIf="!id" (click)="SubmitButton(plantMasterForm)" nz-button class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>Submit</button>
                <button *ngIf="id" (click)="SubmitButton(plantMasterForm)" nz-button class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>Update</button>
                <button *ngIf="id" (click)="SubmitButton(plantMasterForm, 'deactivate')"  nz-button class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>De-activate</button>
                <button (click)="Cancel()" nz-button class="ml-10" nzType="default"><i nz-icon nzType="close"  nzTheme="outline"></i>Cancel</button>

            </div> -->
            <div *nzModalFooter>
                <div nz-row>
                    <div nz-col nzSpan="8" class="text-left">
                        <button *ngIf="id && plantStatus == 1" (click)="activateDeActivatePlant(plantMasterForm, 'deactivate')"  [disabled]="!plantMasterForm.value.plant_name" nz-button class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>De-activate</button>
                        <button *ngIf="id && plantStatus == 0" (click)="activateDeActivatePlant(plantMasterForm, 'activate')" [disabled]="!plantMasterForm.value.plant_name"  nz-button class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>Activate</button>
                    </div>
                    <div nz-col nzSpan="12" nzOffset="4" class="text-right">
                        <button (click)="cancel()" nz-button class="ml-10" nzType="default"><i nz-icon nzType="close"  nzTheme="outline" ></i>Cancel</button>
                        <button *ngIf="!id" (click)="SubmitButton(plantMasterForm, 'save')" nz-button class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>Submit</button>
                        <button *ngIf="id" (click)="SubmitButton(plantMasterForm, 'update')" [disabled]="(plantMasterForm.value.plant_name == originalValues?.plant_name && plantMasterForm.value.plant_location == originalValues?.plant_location)" nz-button class="ml-10" nzType="primary"><i nz-icon nzType="send"  nzTheme="outline"></i>Update</button>
                    
                       
                    </div>
                    
                </div>
               
              </div>
        </form>

</div>

<nz-modal [(nzVisible)]="isSignatureModal" nzClassName="modal-no-footer" nzTitle="Electronic Signature"
    (nzOnCancel)="isSignatureModal=false;">
    <app-electronic-signature (AuthenticateEvent)="authenticateEvent($event)" [auditObjectData]="auditObject"  [deactivate]="isDeactivate"
        (SignaturepopupClose)="closeSignatureModal($event)"></app-electronic-signature>
</nz-modal>