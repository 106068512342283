import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { MasterAPIResolver } from './core/services/master-api.resolver';
import { ElectronicSignatureComponent } from './shared/components/electronic-signature/electronic-signature.component';
import { ModuleSectionComponent } from './shared/components/module-section/module-section.component';

const routes: Routes = [
  {
    path:'',
    loadChildren:() =>import('./publicpages/publicpages.module').then(m =>m.PublicpagesModule),
  },
  {
    path:'administration',
    loadChildren:() =>import('./administration/administration.module').then(m=>m.AdministrationModule),
    // canActivate : [AuthGuard],
    // resolve: { masterAPI1: MasterAPIResolver }
  },
  {
    path:'user-profile',
    loadChildren:() => import('./user-profile/user-profile.module').then(m=>m.UserProfileModule),
    // canActivate : [AuthGuard],
    // resolve: { masterAPI1: MasterAPIResolver }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
