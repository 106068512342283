import { Component } from "@angular/core";
import { LoaderService } from "src/app/core/services/loader.service";
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less']
})
export class LoaderComponent {
  show!: boolean;
  constructor(private _loaderService: LoaderService) {}

  ngOnInit() {
    this._loaderService.loadState.subscribe((res: any) => {
      this.show = res;
    });
  }
}
