import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './core/services/auth.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { SessionTimeoutComponent } from './shared/components/session-timeout/session-timeout.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { PolicySettingsService } from 'src/app/administration/policy-settings/policy-settings.service';
import { environment } from 'src/environments/environment';
import { StorageService } from './services/storage.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  title = 'casemanagement';
  currentUser: any;
  isShowPopup: boolean = false;
  public logoutCounter = 60;
  loginCounter!: number;
  loginInterval: any;
  confirmModal?: NzModalRef;
  isTimeoutModal: boolean = false;
  @HostListener('window:mousemove')
  refreshUserState(): void {
    if (this.logoutCounter >= 60) {
      this.loginCounter = 0;
    }
  }
  @HostListener('window:keydown')
  refreshUserStateKey(): void {
    if (this.logoutCounter >= 60) {
      this.loginCounter = 0;
    }
  }
  @HostListener('window:unload')
  beforeunloadHandler(): void {
    this.authService.logout();
    this.storageService.setItem('logout', 'On Tab Close');
  }
  constructor(
    private authService: AuthService,
    private router: Router,
    private modalService: NzModalService,
    private bnIdle: BnNgIdleService,
    private storageService: StorageService,
    private policySettingsService: PolicySettingsService,
    private idle: Idle
  ) {
    this.getPolicySettings();
  }

  ngOnInit() {
    this.getPolicySettings();
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
           let userData = this.storageService.getItem('userDetails');
           if(userData == undefined) { 
              this.router.navigate(['/login']); 
           }
      }
  });
  }

  getPolicySettings() {
    this.policySettingsService.getPolicySettings().subscribe((res: any) => {
      this.storageService.setItem('timeout', res[0].session_timeout);

      let sessionTimeOut: any = this.storageService.getItem('timeout');
      let timeOutValue = parseInt(sessionTimeOut) * 60;
      console.log(`TIMEOUT VALUE IS ${timeOutValue} Seconds`);
      this.idle.stop();
      // sets an idle timeout of 5 seconds, for testing purposes.
      this.idle.setIdle(timeOutValue);
      // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
      this.idle.setTimeout(10);
      // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  
      this.idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer this.idle.';
        console.log(this.idleState);
        this.reset();
      });
  
      this.idle.onTimeout.subscribe(() => {
        this.isTimeoutModal = false;
        this.idleState = 'Timed out!';
        this.timedOut = true;
        this.logout();
      });
  
      this.idle.onIdleStart.subscribe(() => {
        this.idleState = "You've gone idle!";
        console.log(this.idleState);
        this.isTimeoutModal = true;
      });
  
      this.idle.onTimeoutWarning.subscribe((countdown) => {
        this.idleState = 'You will time out in ' + countdown + ' seconds!';
      });
      this.authService.getUserLoggedIn().subscribe((userLoggedIn) => {
        if (userLoggedIn) {
          this.idle.watch();
          this.timedOut = false;
        } else {
          this.idle.stop();
        }
      });
    });
  }

  startTimer() {
    this.policySettingsService.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.modalService.closeAll();
        clearInterval(this.policySettingsService.interval);
        // this.bnIdle.stopTimer();
        this.authService.logout();
      }
    }, 1000);
  }

  timeLeft: any = 10;

  logout() {
    this.idle.stop();
    this.isTimeoutModal = false;
    this.authService.logout();
  }

  idleState = 'Not started.';
  timedOut = false;
  lastPing!: Date;

  reset() {
    this.idle.watch();
    //xthis.idleState = 'Started.';
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.isTimeoutModal = false;
  }

  stay() {
    this.isTimeoutModal = false;
    this.reset();
  }
  showLogoutConfirmPopup(): void {
    this.confirmModal = this.modalService.confirm({
      nzTitle: "You're being timed out due to inactivity.",
      nzContent:
        'Please choose to stay signed in or logoff. Otherwise, you will logged off automatically.',
      nzCancelText: 'Cancel',
      nzOkText: 'Logout',
      nzOnOk: () => {
        this.logout();
      },
      nzOnCancel: () => {
        this.stay();
      },
    });
  }
}
