import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-user-logout',
  templateUrl: './user-logout.component.html',
  styleUrls: ['./user-logout.component.less']
})
export class UserLogoutComponent implements OnInit {
  UserData: any;
  moduleData:any

  constructor( private authService: AuthService, private router: Router, private storageService:StorageService) {
   }

  ngOnInit(): void {
    let data: any = this.storageService.getItem("userDetails")
    this.UserData = JSON.parse(data);
    this.moduleData = this.storageService.getItem("moduleName")
  }
  logout() {
    this.authService.logout();
  }
  profile() {
    if(!this.router.url.includes('module-section')) {
      this.router.navigate(['/user-profile/profile']);
    }
  }
}
