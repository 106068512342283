import { Component, Input, OnChanges, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/core/services/common.service';
import { MailService } from '../../services/mail.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthService } from 'src/app/core/services/auth.service';
import { CaseService } from '../../services/cases.service';
import { DatePipe } from '@angular/common';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzShowUploadList, NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
declare var document: any;

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.less']
})
export class MailComponent implements OnInit, OnChanges {
  @Input('singleCaseData') singleCaseData: any;
  @Output() popupClose = new EventEmitter();
  @Output() investigationReportStatus = new EventEmitter();
  inputValue: any;
  validateForm = this.fb.group({
    to: ['', [Validators.compose([Validators.pattern(/^([a-z][a-z0-9_.]+@([a-z0-9-]+\.)+[a-z]{2,6}(,|, )*)+$/), Validators.required])]],
    cc: ['', [Validators.compose([Validators.pattern(/^([a-z][a-z0-9_.]+@([a-z0-9-]+\.)+[a-z]{2,6}(,|, )*)+$/)])]],
    subject: ['', [Validators.required]],
    description: ['', [Validators.required]],
    userId: [null],
    caseId: [null],
    state: [null],
    type: [''],
    filename: [null],
    attachment: ['']
  });
  showSuccess = false;
  userData: any;
  mailpopupdata: any;
  isFileAttached: boolean = true;
  isLoading = false;
  rerpondToReporter: any = [];
  successMsg: any;
  loaderText: any = {
    button: 'Send',
    loaderButton: 'Sending'
  }
  isSignatureModal = false;
  auditObject: any = {};
  fileNamesArray: any = [];
  filePathArray: any = [];
  remarks: any;
  sourceDocfileList: NzUploadFile[] = [];
  nzShowUploadList: NzShowUploadList = {
    showRemoveIcon: true,
    showDownloadIcon: true
  };
  @ViewChild("bodyTemplate") bodyTemplate!: ElementRef;
  constructor(private fb: FormBuilder, private mailService: MailService,
    private commonService: CommonService,
    private sanitizer: DomSanitizer,
    private storageService:StorageService,
    private caseService: CaseService,
    private authService: AuthService,
    private notification: NzNotificationService,
    public datepipe: DatePipe,
    private modal: NzModalService) {
    this.userData = this.authService.getUserData();
  }

  ngOnInit(): void {
    this.validateForm.controls.userId.setValue(this.userData.id);
    this.validateForm.controls.state.setValue(this.userData.roleName);
  }

  ngOnChanges(changes: any) {

    this.updateModal(changes);

  }

  updateModal(changes: any) {
    if (changes.singleCaseData) {
      this.mailpopupdata = changes.singleCaseData.currentValue;
      if (this.mailpopupdata) {
        this.validateForm.controls.to.setValue(this.mailpopupdata.reporterEmail);
        this.validateForm.controls.caseId.setValue(this.mailpopupdata.id);
        this.validateForm.controls.type.setValue(this.mailpopupdata.type);
        if (this.mailpopupdata.type == "caseAcknowledgement") {
          this.validateForm.controls.subject.setValue("Acknowledgement for case " + this.mailpopupdata.registrationId)
          this.getAckMailTemplate(1);
          this.successMsg = "Acknowledgement Mail Sent Successfully";
        }
        else if (this.mailpopupdata.type == "casefollowUp") {
          this.validateForm.controls.subject.setValue("Followup for case " + this.mailpopupdata.registrationId)
          this.getFollowupTemplate();
          this.successMsg = "Followup Mail Sent Successfully";
        }
        else if (this.mailpopupdata.type == "respondToReporter") {
          this.validateForm.controls.subject.setValue("Respond to reporter for case " + this.mailpopupdata.registrationId);
          this.getCaseConversations();
          this.bodyTemplate.nativeElement.innerHTML = "";
          this.successMsg = "Respond to reporter Mail Sent Successfully";
        }
        else if (this.mailpopupdata.type == "emailForward") {
          this.validateForm.controls.subject.setValue("Forward: " + this.mailpopupdata.caseSubject);
          this.getForwardMailTemplate(8);
          this.successMsg = "Mail Case Forwarded Successfully";
        }
        else if (this.mailpopupdata.type == "caseRejectedAcknowledgement") {
          this.validateForm.controls.subject.setValue("Acknowledgement for rejected case " + this.mailpopupdata.registrationId)
          this.getAckMailTemplate(5);
          this.successMsg = "Rejected Case Acknowledgement Mail Sent Successfully";
        }
        else if (this.mailpopupdata.type == "caseInvestigationReport") {
          this.validateForm.controls.subject.setValue("Investigation report for case : " + this.mailpopupdata.registrationId);
          this.getAckMailTemplate(9);
          this.successMsg = "Investigation Report Mail Sent Successfully";
        }
      }
    }
  }

  uplaodMailAttachment() {    
    if (this.sourceDocfileList.length > 0) {
      const formData = new FormData();
      this.sourceDocfileList.forEach((file: any) => {
        formData.append('files', file.originFileObj);
      });
      this.mailService.uploadMailAttachment(formData).subscribe((res: any) => {
        let sourceDocfileList: any = this.sourceDocfileList;
        this.sourceDocfileList = [];
        this.fileNamesArray = [];
        this.filePathArray = [];
        sourceDocfileList.forEach((doc: any) => {
          this.sourceDocfileList.push({
            uid: doc.uid,
            name: doc.name,
            status: 'done',
            url: environment.baseUrl + 'download/download-file/' + doc.name
          });
          this.fileNamesArray.push(doc.name);
          this.filePathArray.push({ path: environment.baseUrl + 'download/download-file/' + doc.name });
        });
        this.validateForm.controls["filename"].setValue(this.fileNamesArray);
        this.validateForm.controls["attachment"].setValue(this.filePathArray);
      }, (error: any) => {

      })
    }
  }

  downloadDoc(doc: any) {
    window.location.assign(environment.baseUrl + 'download/download-file/' + doc);
  }

  sendFollowupData(data: any) {
    this.isSignatureModal = true;
  }
  authenticateEvent(event: any) {
    this.remarks = event;
    this.isSignatureModal = false;
    setTimeout(() => {
      this.modal.closeAll();
      this.notification.create(
        'info',
        'Sending mail',
        'You will be notified once mail gets sent'
      );
    }, 1000);
    this.sendMailFunction(this.remarks)
  }

  sendMailFunction(event: any) {
    this.validateForm.controls.description.setValue(this.bodyTemplate.nativeElement.innerHTML);
    this.mailService.sendMail(this.validateForm.value).subscribe((res: any) => {
      this.showSuccess = true;
      this.isFileAttached = false;
      this.auditObject = this.commonService.saveAuditTrialsObject('Sent mail',event.remarks,'Followup Case Audit Trails' );
      this.isSignatureModal = false;
      this.notification.create(
        'success',
        'Mail Sent',
        this.successMsg
      );
      this.closeModal();
      if (this.mailpopupdata.type == "caseInvestigationReport") {
        this.investigationReportStatus.emit(true);
      }
    }, error => {
      this.isSignatureModal = false;
      this.showSuccess = true;
      this.isFileAttached = false;
      this.notification.create(
        'error',
        'Mail Not Sent',
        'Error while sending the Mail. Please Try Again After Sometime'
      );
      this.closeModal();
      if (this.mailpopupdata.type == "caseInvestigationReport") {
        this.investigationReportStatus.emit(true);
      }
    });
  }
  closeModal() {
    this.popupClose.emit(false);
    let array: any = [];
    this.validateForm.controls.attachment.setValue(array);
    this.validateForm.controls.filename.setValue(array);
    this.isFileAttached = false;
    setTimeout(() => {
      this.isFileAttached = true;
    });
  }
  getFollowupTemplate() {
    let body = {
      to: this.mailpopupdata.reporterEmail,
      type: 'FollowUpQuery',
      templateId: 2,
      reporterName: this.mailpopupdata.reporterName,
      registrationId: this.mailpopupdata.registrationId,
      reportingTime: moment(new Date()).format('YYYY-MM-DD HH:MM')
    }
    this.commonService.getAcknowledgementTemplate(body).subscribe((response) => {
      this.bodyTemplate.nativeElement.innerHTML = response.body
      this.validateForm.controls.description.setValue(response.body);
    })
  }
  getAckMailTemplate(templateId: any) {
    let body = {
      to: this.mailpopupdata.reporterEmail,
      type: 'DynamicTemplate',
      templateId: templateId,
      reporterName: this.mailpopupdata.reporterName,
      registrationId: this.mailpopupdata.registrationId,
      reportingTime: moment(new Date()).format('YYYY-MM-DD HH:MM')
    }
    this.commonService.getAcknowledgementTemplate(body).subscribe((response) => {
      this.bodyTemplate.nativeElement.innerHTML = response.body
      this.validateForm.controls.description.setValue(response.body);
    })
  }

  getForwardMailTemplate(templateId: any) {
    this.mailpopupdata.templateId = templateId;
    let body = this.mailpopupdata;
    this.commonService.getAcknowledgementTemplate(body).subscribe((response) => {
      this.bodyTemplate.nativeElement.innerHTML = response.body
      this.validateForm.controls.description.setValue(response.body);
    })
  }
  getLoaderMessage() {
    if (this.storageService.getItem('loader') == "true") {
      return true;
    }
    this.isLoading = false;
    return false;
  }

  getCaseConversations() {
    this.caseService.getCaseFollowUpsById(this.mailpopupdata.id, 'RESPOND_TO_REPORTER').subscribe((res: any) => {
      this.rerpondToReporter = res;
    }, (error: any) => {

    })
  }

  closeSignatureModal(event: any) {
    this.isSignatureModal = event;
  }

  onMailBodyInput(event: any) {
    if (event.target.textContent.trim()) {
      this.validateForm.controls.description.setValue(event.target.innerHTML);
    }
    else {
      this.validateForm.controls.description.setValue(null);
    }
    this.validateForm.controls.description.updateValueAndValidity();
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    this.sourceDocfileList = this.sourceDocfileList.concat(file);
    return false;
  };  

  handleRemove = (file: any) => new Observable<boolean>((obs) => {    
    if (file.showDownload) {
    } else {
      obs.next(true);
    }
  });
}
