
<nz-layout>
    <div class="site-page-header-ghost-wrapper">
        <!-- <nz-header class="px-2">
            <app-header></app-header>
        </nz-header>
        <app-breadcrumb></app-breadcrumb> -->
        <nz-layout>
            <app-side-nav></app-side-nav>
        </nz-layout>
     </div>
</nz-layout>