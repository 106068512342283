<div nz-row>
    <div nz-col [nzSpan]="5" nzOffset="19" class="px-20"  nzJustify="center">
        <nz-form-item>
            <nz-form-control>
            
                    <!-- <button nz-button nzSize="small" nzType="primary"  class="search-button">Search</button> -->
                    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                        <input type="text"  [(ngModel)]="searchTerm" (input)="searh()"  nz-input placeholder="Search Here..." />
                      </nz-input-group>
                      <ng-template #suffixIconButton>
                        <button  nz-button nzType="primary" (click)="filterdata()"  class="search" nzSearch><i nz-icon
                            nzType="search"></i>
                        </button>
                      </ng-template>
            </nz-form-control>
        </nz-form-item>
    </div>
</div>
<div class="text-right pb-10">
    <button nz-button class="mx-15" nzType="primary" style="cursor: pointer;" (click)="print()" [useExistingCss]="true" printSectionId="print-section" ngxPrint><i nz-icon nzType="printer"
      ></i> Print</button>
<nz-table class="table_th_bg mx-15" nzBordered  #sortTable [nzData]="data" [nzPageSize]="pageSize" (nzPageSizeChange)="onPageSizeChange($event)" id="print-section" nzShowSizeChanger #basicTable >
    <thead> 
        <tr>
            <th class="font-weigth-bold" *ngFor="let col of columns">{{col.name}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of sortTable.data">
            <td *ngIf="form == 'USER_FORM'"> {{data.userId}} </td>
            <!-- <td *ngIf="form == 'PLANT_MASTER_FORM'"> {{data.plant_id}} </td> -->
            <td *ngIf="form == 'PLANT_MASTER_FORM'"> {{data.plant_name}} </td>
            <td *ngIf="form == 'USER_ROLES_FORM'"> {{data.name}} </td>
            <td *ngIf="form == 'DEPARTMENTS_FORM'"> {{data.dept_name}} </td>
            <td *ngIf="form == 'PLANT_MASTER_FORM'"> {{data.plant_location}} </td>
            <td *ngIf="form=='USER_FORM'"> {{data.roleName}} </td>
            <td *ngIf="form == 'DEPARTMENTS_FORM'"> {{data.dept_description}} </td>
            <td *ngIf="form=='USER_FORM'"> {{data.username}} </td>
            <td *ngIf="form == 'PLANT_MASTER_FORM'"> {{data.active}}</td>
            <td *ngIf="form == 'PARAMETERS_FORM'">{{data.parameter_name}}</td>
            <td *ngIf="form == 'PARAMETERS_FORM'">{{data.description}}</td>
            <td *ngIf="form == 'PARAMETERS_FORM'">{{data.device}}</td>
            <td *ngIf="form == 'CONDITIONS_FORM'">{{data.condition_name}}</td>
            <td *ngIf="form == 'CONDITIONS_FORM'">{{data.description}}</td>
            <!-- <td *ngIf="form == 'USER_ROLES_FORM'"> {{data.active}} </td> -->
            <td *ngIf="(data.active == '0' || data.active == '2') && form != 'PLANT_MASTER_FORM'">In-Active</td>
            <td *ngIf="data.active == '1' && form != 'PLANT_MASTER_FORM'">Active</td>
            <td *ngIf="form == 'USER_FORM' && data.roleName!='SuperAdmin'">
                <a [routerLink]="['/administration/admin/user-registration/form', data.id]">
                    <i nz-icon nzType="edit" nzTheme="outline"></i>
                    Preview
                </a>
            </td>
            <td *ngIf="form == 'USER_FORM' && data.roleName=='SuperAdmin'">
                <a href="javascript:void(0)" class="disabled-link" disabled="true">
                    <i nz-icon nzType="edit" nzTheme="outline"></i>
                        Preview
                </a>
            </td>
            <td *ngIf="form == 'PLANT_MASTER_FORM'">
                <a 
                    (click)="editplant(data.id)"
                    
                >
                    <i nz-icon nzType="edit" nzTheme="outline"></i>
                    Preview
                </a>
            </td>
            <td *ngIf="form == 'DEPARTMENTS_FORM'">
                <a (click)="editdepartment(data.id)">
                    <i nz-icon nzType="edit" nzTheme="outline"></i>
                    Preview
                </a>
            </td>
            <td *ngIf="form == 'PARAMETERS_FORM'">
                <a (click)="editParameter(data.id)">
                    <i nz-icon nzType="edit" nzTheme="outline"></i>
                    Preview
                </a>
            </td>
            <td *ngIf="form == 'CONDITIONS_FORM'">
                <a (click)="editCondition(data.id)">
                    <i nz-icon nzType="edit" nzTheme="outline"></i>
                    Preview
                </a>
            </td>
            <td *ngIf="form == 'USER_ROLES_FORM'">
                <a [routerLink]="['/administration/admin/user-roles/permissions', data.id,data.name,data.masterRoleId, data.active]">
                    <i nz-icon nzType="edit" nzTheme="outline"></i>
                    Preview
                </a>
            </td>

        </tr>
    </tbody>
</nz-table>

<nz-empty class="mt-10" *ngIf="data?.length == 0"></nz-empty>


<!-- <app-pagination (onPageChangeEvent)='getPageData($event)' (onPageSizeChangeEvent)='changepageSize($event)'
    [pageNo]="pageNo" [pageSize]="pageSize" [totalLength]="dataLength" *ngIf="data.length != 0"></app-pagination> -->
<!-- <router-outlet></router-outlet> -->