<div nz-row nzJustify="space-between">
    <div nz-col nzSpan="18">
        <div nz-row nzJustify="start" class="pointer">
            <div nz-col (click)="profile()">
                <nz-avatar [nzSize]="40" nzIcon="user" style="background-color:rgba(38, 38, 38, 0.8);"></nz-avatar>
            </div>
            <div nz-col nzSpan="18" class="pl-5 text-left" (click)="profile()" style="display: grid;">
                <h2 class="mb-0 lh-1 user_role">{{UserData.username}}</h2>
                <small>{{UserData.roleName}}</small>
            </div>
        </div>
    </div>
    <div nz-col nzSpan="4" class="text-right">
        <a (click)="logout()">
            <i nz-icon nzType="logout" class="pt-10" nzTheme="outline"></i>
        </a>
    </div>
</div>
