import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
@Injectable({providedIn: 'root'})
export class PermissonsService {
    authorities: any;
    userdata: any = [];
    
    constructor( private storageService:StorageService) {}
  
    setAuthorities(authorities: string[]) {
      this.authorities = authorities;
    }
  
    getAuthorities() {
      this.userdata = this.storageService.getItem('permissions') ? this.storageService.getItem('permissions') : [];
      const authorities = this.userdata;
      return authorities ? authorities : [];
    }
  
    hasAccess(authorities: string[]) {
      this.authorities = this.getAuthorities();
      if (!authorities) {
        return false;
      }
  
      for (const i of authorities) {
        if (this.authorities.includes(i)) {
          return true;
        }
      }
  
      return false;
    }
    
}