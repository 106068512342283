import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class MailService {

    SEND_MAIL_URL = 'send-mail';
    UPLOAD_MAIL_ATTACHMENT_URL = 'upload-mail-attach';
    constructor(private httpClient: HttpClient) { }

    sendMail(obj: any) {
      return  this.httpClient.post(this.SEND_MAIL_URL, obj);
    }

    uploadMailAttachment(obj: any) {
      return this.httpClient.post(this.UPLOAD_MAIL_ATTACHMENT_URL, obj);
    }
    
}