<nz-layout>
  <nz-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="py-3"> </div>
          <h3 class="h4-400 text-center"> <i nz-icon nzType="check-circle" class="fs-40" nzTheme="fill"
              style="color:green"></i><br>
            <span class="pt-4">{{message}}</span>
          </h3>
          <div class="py-3"> </div>
      </div>
    </div>

  </nz-content>
</nz-layout>

<!-- <nz-content>
   <div class="row">
    <div class="col-sm-12">
      <h3 class="h4-400"> <i nz-icon nzType="check-circle" class="pl-5 pt-1" nzTheme="fill" style="float: left;color:green"></i>&nbsp;
        <span class="ml-3">{{message}}</span></h3>
    </div>
  </div> 
  
</nz-content> -->