import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  GET_DEPARTMENTS_URL = 'admin/masters/getdepartments';
  POST_DEPARTMENTS_URL = 'admin/masters/createdepartment';
  UUPDATE_DEPARTMENT_URL = `admin/masters/updatedepartment`;
  DEACTIVATE_DEPARTMENT_RESOURCE_URL = 'admin/masters/department/de-activate';
  ACTIVATE_DEACTIVATE_DEPARTMENT_RESOURCE_URL = 'admin/masters/department/activate-de-activate';
  selectedTabIndex: any = 0;

  constructor(private httpClient: HttpClient) { }

  getDepartments(value: any) {
    return this.httpClient.get(this.GET_DEPARTMENTS_URL + '?status=' + value);
  }

  postDepartment(body: any) {
    return this.httpClient.post(this.POST_DEPARTMENTS_URL, body)
  }
  updateDepartment(body: any) {
    return this.httpClient.put(this.UUPDATE_DEPARTMENT_URL, body)
  }
  getDepartmentDataById(id: any) {
    return this.httpClient.get(this.GET_DEPARTMENTS_URL + '/' + id);
  }
  deActivateDepartment(id: any, formData: any)  {
    return this.httpClient.put(this.DEACTIVATE_DEPARTMENT_RESOURCE_URL + '/'  + id, formData);
  }

  activateDeActivateDepartment(id: any, status: any, formData: any)  {
    return this.httpClient.put(this.ACTIVATE_DEACTIVATE_DEPARTMENT_RESOURCE_URL + `/${id}/${status}`, formData);
  }
  
}
