import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { FormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class CaseService {
    userData: any;
    GET_CASES_LIST_BY_SOURCE_URL = `case/casesbysources`;
    GET_HEALTH_AUTHORITY_CASES_URL = `health/getcases`;
    GET_USER_CASES_LIST_URL = `case/caseslist/user/`;
    GET_CASES_BY_CLASSIFICATION_URL = `case/casesbyclassfication`;
    GET_CASES_BY_STATUS_URL = `case/initiated-cases/`;
    GET_CASES_BY_VALIDITIY_URL = `case/casesbyvalidity`;
    GET_MAIL_CASES_COUNTS_URL = `case/getmailcasescounts`;
    MAIL_CASES_MARK_AS_READ_URL = `case/updateCaseViewStatus/`;
    POST_ASSIGNED_CASES_URL = `case/assign-cases`;
    GET_CASE_LOG_BY_ID = `case/getcaselog/`;
    GET_CASE_FOLLOWUP_BY_ID = `case/getfollowup/`;
    GET_CASE_LIST_REASSIGN =`case/cases-filter`;
    POST_REASSIGN=`case/assign-cases`;
    DELETE_DOCUMENT_BY_ID = `case/deletedocument/`;
    DELETE_DOCS_REPO_BY_ID = `case/deletedocsrepo/`;
    GET_CASE_DOWNLOAD_PDF_URL = `case/casedownloadpdf/`;
    GET_LM_ARTICLES_URL = `lm/getallarticles`;
    GET_LM_ARTICLES_BY_ID_URL = `lm/getarticlebyid`;
    GET_LM_ARTICLES_IS_OPPEN_BY_ID_URL = `lm/getarticleisopenbyid`;
    UPDATE_LM_ARTICLES_ISOPEN_BY_ID_URL = `lm/updatearticleisopenbyid`;
    GET_PUBMED_ARTICLES_URL = 'lm/get-pubmed-articles';
    GET_ADIS_INSIGHT_ARTICLES_URL = 'lm/get-adisinsight-articles';
    GET_LLS_ARTICLES_URL = 'lm/get-lls-articles';
    GET_MEDICAL_LITERATURE_ARTICLES_URL = `lm/get-medical-literture-articles`;
    GET_CHECKLIST_URL   = `lm/get-checklist`;
    UPDATE_ARTICLE_URL = `lm/updatarticle`;
    SEARCH_MLM_ARTICLE_URL = `lm/searchmlmaritlce`;
    UPDATE_MEDICAL_REVIEWER_ARTICLE_URL = `lm/updatearticle-seriousness`;
    GET_MEDICAL_REVIEWERS_URL   = `lm/get-medical-reviewers`;
    SUMIT_FOR_ADR_URL = ``;
    GET_MI_CASES_LOGS_BY_USERID = `case/getMIcasesStats`;
    DELETE_REPORTER_BY_ID = `case/reporters-by-id/`;
    DELETE_EVENT_BY_ID = `case/event-by-id/`;
    GENERATE_ARGUS_NUMBER = `case/generate-argus-number`;
    POST_CASE_EVENTS = `case/savecaseevents`;
    GET_CASE_EVENTS = `case/events-by-caseid`;
    UPDATE_CASE_ISOPEN_BY_ID_URL = `case/updatecaseisopenbyid`;
    GET_CASE_ISOPEN_BY_ID_URL = `case/getIsCaseOpen`;
    disableForm: boolean = false;
    newSelectedProducts: any = [];
    sourceName: any = null;
    primaryReporter: any = null;
    isProductAdded: boolean = true;
    
    constructor(private httpClient: HttpClient, private authService: AuthService) { 
        this.userData = this.authService.getUserData();
    }

    getCasesBySources(body: any) {
        return this.httpClient.post(this.GET_CASES_LIST_BY_SOURCE_URL, body);
    }

    getHealthAuthorityCases(body: any) {
        return this.httpClient.post(this.GET_HEALTH_AUTHORITY_CASES_URL, body);
    }

    getCasesByUser(id: any, status: any, sourceId: any) {
        return this.httpClient.get(this.GET_USER_CASES_LIST_URL + id + `/` + status + '/' + sourceId);
    }

    getCasesByClassification(body: any) {
        return this.httpClient.post(this.GET_CASES_BY_CLASSIFICATION_URL, body);
    }

    getCasesByValidity(body: any) {
        return this.httpClient.post<any>(this.GET_CASES_BY_VALIDITIY_URL, body);
    }

    getMailCasesCounts(body: any) {
        return this.httpClient.post<any>(this.GET_MAIL_CASES_COUNTS_URL, body);
    }

    updateCaseViewStatus(caseId: any, status: any) {
        return this.httpClient.put<any>(this.MAIL_CASES_MARK_AS_READ_URL + caseId + "/"+ status, null);
    }

    getInitiatedCases(status: any, priority: any, classificationId: any) {
        return this.httpClient.get<any>(this.GET_CASES_BY_STATUS_URL + status + '/' + priority + '/' + classificationId);
    }

    assignCases(obj: any) {
        return this.httpClient.post<any>(this.POST_ASSIGNED_CASES_URL, obj);
    }

    getCaselogById(id: any) {
        return this.httpClient.get(this.GET_CASE_LOG_BY_ID + id);
    }

    getCaseFollowUpsById(id: any, type: any, body?: any) {
        return this.httpClient.post(this.GET_CASE_FOLLOWUP_BY_ID + id + "/" + type, body);
    }

    deleteDocumentById(id: any) {
        return this.httpClient.get(this.DELETE_DOCUMENT_BY_ID + id);
    }

    deleteDocsRepoById(id: any, body: any) {
        return this.httpClient.post(this.DELETE_DOCS_REPO_BY_ID + id, body);
    }

    getCaseReassignData(data:any, module: any){
        return this.httpClient.post(this.GET_CASE_LIST_REASSIGN + "/" + module, data)
    }
    reAssignCase(data:any){
        return this.httpClient.post(this.POST_REASSIGN,data)
    }
    caseDownloadPDF(id:any) {
        return this.httpClient.get(this.GET_CASE_DOWNLOAD_PDF_URL + id)
    }

    getLmArticles() {
        return this.httpClient.get<any>(this.GET_LM_ARTICLES_URL);
    }

    getArticleById(sources: any, id: any) {
        return this.httpClient.get<any>(this.GET_LM_ARTICLES_BY_ID_URL + `/${sources}/${id}`);
    }
    getArticleIsOpenById(sources: any, id: any) {
        return this.httpClient.get<any>(this.GET_LM_ARTICLES_IS_OPPEN_BY_ID_URL + `/${sources}/${id}`);
    }
    updateArticleIsOpenById(data: any) {
        return this.httpClient.put<any>(`${this.UPDATE_LM_ARTICLES_ISOPEN_BY_ID_URL}`,data);
    }
    getIsArticleOpen(body: any) : Observable<any>{
        return this.httpClient.post(this.GET_PUBMED_ARTICLES_URL, body)
    }
    getPubmedArticlesInfo(body: any) : Observable<any>{
        return this.httpClient.post(this.GET_PUBMED_ARTICLES_URL, body)
    }
    getAdisinsightArticlesInfo(body: any) : Observable<any>{
        return this.httpClient.post(this.GET_ADIS_INSIGHT_ARTICLES_URL, body)
    }
    getLlsArticlesInfo(body: any) : Observable<any>{
        return this.httpClient.post(this.GET_LLS_ARTICLES_URL, body)
    }
    getMedicalLiteratureArticlesInfo(body: any) :Observable<any>{
        return this.httpClient.post(this.GET_MEDICAL_LITERATURE_ARTICLES_URL, body);
    }
    getChecklist() :Observable<any> {
        return this.httpClient.get(this.GET_CHECKLIST_URL);
    }
    updateLlsArticle(obj: any) : Observable<any>{
        return this.httpClient.put(`${this.UPDATE_ARTICLE_URL}/lls`, obj);
    }
    updateAdisInsightArticle(obj: any) : Observable<any>{
        return this.httpClient.put(`${this.UPDATE_ARTICLE_URL}/Adisinsight`, obj);
    }
    updatePubmedArticle(obj: any) : Observable<any>{
        return this.httpClient.put(`${this.UPDATE_ARTICLE_URL}/Pubmed`, obj);
    }
    updateMlmArticle(obj: any) : Observable<any>{
        return this.httpClient.put(`${this.UPDATE_ARTICLE_URL}/mlm`, obj);
    }
    searchMlmArticle(obj: any) : Observable<any>{
        return this.httpClient.post(`${this.SEARCH_MLM_ARTICLE_URL}`, obj);
    }
    updateMedicalReviewerArticle(obj: any, type: any) : Observable<any>{
        return this.httpClient.put(`${this.UPDATE_MEDICAL_REVIEWER_ARTICLE_URL}/${type}`, obj)
    }
    getMedicalReviewersList() :Observable<any>{
        return this.httpClient.get(this.GET_MEDICAL_REVIEWERS_URL)
    }
    submitForAdr(obj: any, type: any) :Observable<any>{
        return this.httpClient.post(`${this.SUMIT_FOR_ADR_URL}/${type}`, obj)
    }

    getMICasesLogsUserId(body: any) {
        return this.httpClient.post(this.GET_MI_CASES_LOGS_BY_USERID, body);
    }
    
    deleteReporterById(id: any) {
        return this.httpClient.delete(this.DELETE_REPORTER_BY_ID + id);
    }

    deleteEventById(id: any) {
        return this.httpClient.delete(this.DELETE_EVENT_BY_ID + id);
    }

    generateArgusNumber(source: any, body: any) {
        return this.httpClient.post(this.GENERATE_ARGUS_NUMBER+`/${source}/${this.userData.username}`, body);
    }

    postCaseEvents(id: any, body: any) {
        return this.httpClient.post(this.POST_CASE_EVENTS +`/${this.userData.id}/${id}`, body);
    }

    getCaseEvents(id: any) {
        return this.httpClient.get(this.GET_CASE_EVENTS +`/${id}`);
    }
    updateCaseIsOpenById(caseId: any, isOpen: any) {
        return this.httpClient.get<any>(`${this.UPDATE_CASE_ISOPEN_BY_ID_URL}/${caseId}/${isOpen}`);
    }
    getCaseIsOpenById(caseId: any) {
        return this.httpClient.get<any>(`${this.GET_CASE_ISOPEN_BY_ID_URL}/${caseId}`);
    }

    getDirtyValues(form: FormGroup) {
        let dirtyValues: any = {};

        Object.keys(form.controls).forEach((key) => {
            let currentControl: any = form.controls[key];

            if (currentControl.dirty) {
            if (currentControl.controls)
                dirtyValues[key] = this.getDirtyValues(currentControl);
            else dirtyValues[key] = currentControl.value;
            }
        });

        return dirtyValues;
    }
}