import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlantMasterService {

  GET_PLANTS_URL = 'admin/masters/getplants';
  POST_PLANTS_URL = 'admin/masters/postplant'
  UPDATE_PLANTS_URL =`admin/masters/updateplant`;
  DECATIVATE_PLANT_URL = `admin/masters/de-activate`;
  GET_ACTIVE_PLANTS_URL = 'admin/masters/get-active-plants';
  GET_INACTIVE_PLANTS_URL = 'admin/masters/get-inactive-plants';
  ACTIVATE_DEACTIVATE_PLANT_RESOURCE_URL = 'admin/masters/plant/activate-de-activate';
  constructor(private httpClient:HttpClient) { }

  getPlants() {
    return this.httpClient.get(this.GET_PLANTS_URL);
  }

  postPlants(body: any) {
    return this.httpClient.post(this.POST_PLANTS_URL,body);
  }
  updatePlants(body: any){
    return this.httpClient.put(this.UPDATE_PLANTS_URL,body);
  }
  getPlntMasterDataById(id: any){
    return this.httpClient.get(this.GET_PLANTS_URL + '/' + id);
  }
  deactivatePlants(id: any, obj: any){
    return this.httpClient.put(this.DECATIVATE_PLANT_URL + '/' + id , obj);
  }
  getActivePlants() {
    return this.httpClient.get(this.GET_ACTIVE_PLANTS_URL);
  }
  getInActivePlants() {
    return this.httpClient.get(this.GET_INACTIVE_PLANTS_URL);
  }

  activateDeActivatePlant(id: any, status: any, formData: any)  {
    return this.httpClient.put(this.ACTIVATE_DEACTIVATE_PLANT_RESOURCE_URL + `/${id}/${status}`, formData);
  }


}
