import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ConditionsFormComponent } from 'src/app/administration/masters/conditions/conditions-form/conditions-form.component';
import { DepartmentFormComponent } from 'src/app/administration/masters/department/department-form/department-form.component';
import { DepartmentService } from 'src/app/administration/masters/department/department.service';
import { ParametersFormComponent } from 'src/app/administration/masters/parameters/parameters-form/parameters-form.component';
import { PlantMasterFormComponent } from 'src/app/administration/masters/plant-master/plant-master-form/plant-master-form.component';
import { PlantMasterService } from 'src/app/administration/masters/plant-master/plant-master.service';
import { UserRegistrationService } from 'src/app/administration/user-registration/user-registration.service';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-administration-table',
  templateUrl: './administration-table.component.html',
  styleUrls: ['./administration-table.component.less']
})
export class AdministrationTableComponent implements OnInit {
  @Input('columns') columns: any;
  @Input('data') data: any;
  @Input('form') form: any;
  @Input('closeDepartment') closeDepartment: any;
  @Input('closeParameter') closeParameter:any
  @Input('closeCondition') closeCondition:any
  @Input('closePlant') closePlant: any;
  @Input('closeRoles') closeRoles: any;
  rolesList: any = [];
  deactivatedUsers: any = [];
  q: any;
  searchTerm: any;
  dataLength: any;
  pageNo: any = 1;
  pageSize: any = 10;
  searchInput: any;
  searchInputdummy: any = '';
  plantData: any;
  isPlantModal = false;
  modal: any;
  // @Input('totalRecords') 
  totalRecords: any;
  constructor(private userService: UserRegistrationService, private router: Router, private plantService: PlantMasterService, private modalService: NzModalService, private readonly filterService: CommonService, private departmentService: DepartmentService) {
    // const ref: NzModalRef = modalService.info();
    // ref.close(); // Or ref.destroy(); This dialog will be destroyed directly
  }

  ngOnInit(): void {
    if (this.closeDepartment == true) {
      this.getDepartments();
      this.getDeactivatedDepartments();
    }
    if(this.closeParameter == true){
      // this.get
    }
  }
  getPage(page: number) {
    this.q = page;
  }
  ngOnChanges() {
    this.totalRecords = this.data;
  }


  getPageData(pageNo: number) {
    this.pageNo = pageNo;

  }

  changepageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNo = 1;

  }

  search() {
    this.searchInput = this.searchInputdummy;
  }
  cancel() {
    this.modal.destroy();

  }

  onkeyUp() {
    if (!this.searchInputdummy) {
      this.searchInput = '';
      this.search();
    }
  }

  navigateToPermissions(data: any) {
    this.router.navigate(["/administration/admin/user-roles/permissions", data.id])
  }

  // search(): void {
  //   this.visible = false;
  //   this.listOfDisplayData = this.listOfData.filter((item: DataItem) => item.name.indexOf(this.searchValue) !== -1);
  // }

  editplant(id: any) {
    this.modalService.create({
      nzTitle: 'Plant Master Form',
      nzContent: PlantMasterFormComponent,
      nzComponentParams: {
        id: id
      },
      nzClassName: 'modal-width-50 '
    });
  }
  editParameter(id:any){
    this.modalService.create({
      nzTitle: 'Parameter Form',
      nzContent: ParametersFormComponent,
      nzComponentParams: {
        id: id
      },
      nzClassName:'modal-width-50 '
    });
  }
  editCondition(id:any){
    this.modalService.create({
      nzTitle: 'Condition Form',
      nzContent: ConditionsFormComponent,
      nzComponentParams: {
        id: id
      },
      nzClassName:'modal-width-50 '
    });
  }
  editdepartment(id: any) {
    this.modalService.create({
      nzTitle: 'Department Form',
      nzContent: DepartmentFormComponent,
      nzComponentParams: {
        id: id
      },
      nzClassName: 'modal-width-50 '
    });
  }

  filterdata() {
    var data: any = []
    this.data = this.filterService.filterdata(this.totalRecords, this.searchTerm, data);
  }
  searh() {
    if (this.searchTerm == '') {
      this.data = this.totalRecords;
    }
  }

  getDepartments() {
    this.departmentService.getDepartments(1).subscribe((res: any) => {
      this.data = res;
    }, (error: any) => {
      console.log(error);
    })
  }

  getDeactivatedDepartments() {
    this.departmentService.getDepartments(0).subscribe((res: any) => {
      this.data = res;
    }, (error: any) => {
      console.log(error);
    })
  }
  onPageSizeChange(pageSize: number) {
    this.pageSize = pageSize;
    // Do any other logic you want to perform when the page size changes.
  }
  onPageIndexChange(pageIndex: number) {
    this.pageNo = pageIndex;
    // Do any other logic you want to perform when the page number changes.
  }
  print() {
    // this.pageSize = this.data.length;
    this.myPrint()

  }
  myPrint() {
    let pageInfo:any = this.pageSize
    this.pageSize = 50;
    setTimeout(() => {
      let printContents, popupWin : any;
      printContents = document.getElementById('print-section')?.innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');

      popupWin?.document.open();
      popupWin?.document.write(`
        <html>
          <head>
            <title>Print tab</title>
            <link rel="stylesheet" type="text/css" href="styles.css" />
            <style>
            body{
              font-size:14px !important;
            }
            .ant-table-column-sorter{
              display:none !important;
            }
            .table-backg .ant-table .ant-table-thead > tr > th {
              background: #33559E;
              color: #fff;
              padding:16px 16px;
              border: 1px solid #ccc !important;
          }
          .table_th_bg th {
            background: #2d559b !important;
            color: #fff !important;
            font-size: 14px !important;
        }
        .ant-table-thead > tr > th{
          padding: 12px 10px !important;
          background: #33559E;
          color: #fff;
          border: 1px solid #ccc !important;
          
        }
        .ant-table-thead > tr > th:nth-last-child(1){
         display:none !important;
          
        }
        .ant-table table{
          width: 100%;
          text-align: left;
          border-radius: 2px 2px 0 0;
          border-collapse: separate;
          border-spacing: 0;
        }
        .ant-table tr td:nth-last-child(1){
          display:none !important;
        }
          .ant-pagination {
            display:none !important;
        }
        .table-backg .ant-table .ant-table-thead > tr > th{
          text-align:left !important;
        }
        .table-backg .ant-table tr td {
          min-width: 250px;
          width: 100%;
          padding:8px 16px;
        }
        .text-right{
          display:none !important;
        }
        .actions-col{
          display:none;
        }
        .table-backg .ant-table .ant-table-thead > tr > th {
          background: #33559E;
          color: #fff;
      }
            </style>
          </head>
      <body onload="window.print()">${printContents}</body>
        </html>`
      );
      setTimeout(() => {
        popupWin?.document.close();
        this.pageSize = pageInfo
      }, 5000);

    });
  }


}
