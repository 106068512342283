import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzPlacementType } from 'ng-zorro-antd/dropdown';
import { AuthService } from 'src/app/core/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  listOfPosition: NzPlacementType[] = ['bottomLeft', 'bottomCenter', 'bottomRight', 'topLeft', 'topCenter', 'topRight'];
  UserData: any;
  moduleData:any
  isCollapsed = false;
  logo:any;
  logoValue : boolean = false;
  constructor(private router: Router, private authService: AuthService, private storageService:StorageService) {

  }

  ngOnInit(): void {
    if(this.storageService.getItem('preferencesLogo')!=null || undefined) {
      this.logo = this.storageService.getItem('preferencesLogo');
      this.logoValue = true;
    }
    let data: any = this.storageService.getItem("userDetails")
    this.UserData = JSON.parse(data);
    this.moduleData = this.storageService.getItem("moduleName")
  }
  
  ngAfterViewInit() {
    this.moduleData = this.storageService.getItem("moduleName")
  }

  switchmodule(){
    this.router.navigateByUrl('/module-section');
  }
  changePassword(){
    this.router.navigateByUrl('/literaturemonitering/change-password');
  }
  logout() {
    this.authService.logout();
  }
}
