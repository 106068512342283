import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ca_ES } from 'ng-zorro-antd/i18n';
import { AuthService } from './auth.service';
import { AuditTrailsService } from 'src/app/administration/audit-trails/audit-trails.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  auditObject: any = {};
  usersList: any = [];
  loginForm!: FormGroup;
  manualuploadForm:FormGroup = this.fb.group({
    sourcedate: new FormControl('', [Validators.required])
  });
  uploadResponseData: any;
  PUT_MAIL_TEMPLATES_URL = `mail/mail-templates/`;
  POST_MAIL_TEMPLATES_URL = `mail/mail-templates`;
  GET_MAIL_TEMPLATES_URL = `mail/mail-templates`;
  GET_DYNAMIC_VARAIBLES_URL = `getdynamicvariables`;
  GET_MODULE_PERMISSIONS_URL = `auth/modules`;
  GET_CASE_FILTER_DATA_URL = `masters/getcasereassignfilter`;
  GET_ACKNOWLEDGEMENT_TEMPLATE_URL = `mail/dynamic-template`;
  SEND_MAIL_URL = `send-mail`;
  GET_CASE_COMMENTS_URL = `case/casecomments`;
  GET_CASE_PRODUCTS_URL = `case/products-by-caseId`;
  POST_CASE_COMMENTS_URL = `case/casecomment`;
  GET_MODULE_CASE_STATUS_URL = `case/casemanage-status`;
  GET_PRODUCTS_URL = `masters/products`;
  DELETE_PRODUCTS_URL = `product/delete`;
  SAVE_ENQUIRY_DETAILS_URL = `case/enquiry-details`;
  SAVE_CASE_PRODUCT_DATA_URL = `case/save-case-product-data`;
  UPDATE_CASE_PRODUCT_DATA_URL = `case/update-case-product-data`;
  UPDATE_HEALTH_DETAILS_URL = `health/updatecase/`;
  GET_RECEIPT_NUMBER_URL = `receipt-number`;
  GET_CASE_BY_CLASSIFICATION_URL = `case/casesbyclassfication/`;
  GET_CASES_COUNTS_URL = `case/counts/`;
  GET_UPDATE_DETAILS_URL = `case/updatecase/`;
  GET_CLASSIFICATIONS_URL = `masters/classifications`;
  GET_CONTACT_METHODS_URL = `masters/contact-methods`;
  ADD_PRODUCT_URL = `masters/addproduct`;
  UPDATE_PRODUCT_URL = `masters/updateproduct`;
  GET_COUNTRIES_URL = `masters/countries`;
  GET_CASE_DETAILS_URL = `case/casebyid`;
  GET_CASE_FOLLOWUP_VERSION_URL = `case/getcasefollowupversion`;
  GET_HEALTH_CASE_DETAILS_URL = `health/getcasebyid`;
  GET_REGULATORY_AUTHORITIES_URL = `masters/regulatory-authorities`;
  GET_SOURCE_DOCUMENTS_URL = `case/soucedocuments`;
  DOWNLOAD_SOURCE_DOC_URL = `download`;
  CHANGE_PASSWORD = `changepassword`;
  FORGOT_PASSWORD_URL = `update-password`;
  GET_SECURITY_QUESIONS_URL = `security-questions`;
  REQUEST_PASSWORD = `request-password`;
  GENERATE_ADR_XML = `case/generate-adr-xml`;
  SUBMIT_FOR_MI_AND_ADR_URL = `case/submit-for-ae`;
  GET_CASE_FORM_FIELDS = `case/caseformfields`;
  UPDATE_CASE_FORM_FIELDS = `case/updatecaseformfields`;
  VALIDATE_PASSWORD_RESOURCE_URL = `validate-password`;
  GET_USER_LIST_URL = `get-users-list`;
  GET_USERS_LIST_URL = `get-users-data-list`;
  INSERT_CASE_LOGS = `case/insertlogs`;
  INSERT_CASE_AUDITS = `case/save-case-audits`;
  GET_CASE_AUDITS_URL = `admin/get-audits`;
  userAttemptsData: any = null;
  private readonly subject = new BehaviorSubject<any>('');
  count = this.subject.asObservable();

  private readonly getApisSubject = new BehaviorSubject<any>('');
  getapidata = this.getApisSubject.asObservable();
  NotificationService: any;
  constructor(private http: HttpClient, private auditService: AuditTrailsService,  private storageService:StorageService, private fb: FormBuilder) { }
  alertMessage(type:any,icon:any,message:any) {
    return this.NotificationService.create(
      type,icon,message,
      { nzDuration: 2500 }
    );
  }



  routesEnquiry() {
    return this.http.get<any>(`masters/input-sources`) //NOTUSED
  };
  getApisData(type: any) {
    this.getApisSubject.next(type);
}
  natureEnquiry() {
    return this.http.get<any>(`masters/nature-of-enquirers`) //NOTUSED
  };

  enquirers() {
    return this.http.get<any>(`masters/enquirers`) //NOTUSED
  };

  productData() {
    return this.http.get<any>(`masters/products`); //NOTUSED
  }

  postEnquiryDetails() {
    return this.http.get<any>(`masters/products`); //NOTUSED
  }

  SaveEnquiryDetails(data: any) {
    return this.http.post<any>(this.SAVE_ENQUIRY_DETAILS_URL, data);
  }

  SaveCaseProductData(caseId: any, data: any) {
    return this.http.post<any>(this.SAVE_CASE_PRODUCT_DATA_URL + `/${caseId}`, data);
  }

  UpdateCaseProductData(caseId: any, data: any) {
    return this.http.put<any>(this.UPDATE_CASE_PRODUCT_DATA_URL + `/${caseId}`, data);
  }

  updateHealthDetails(data: any, id: any) {
    return this.http.put<any>(this.UPDATE_HEALTH_DETAILS_URL + id, data);
  }

  getCasesList(id: any, status: any) {
    return this.http.get<any>(`case/caseslist/${id}/${status}` + '/' + id + '/' + status); //NOTUSED
  }

  getRecieptNumber() {
    return this.http.get<any>(this.GET_RECEIPT_NUMBER_URL);
  }

  getCasesByClassification(id: any) {
    return this.http.get<any>(this.GET_CASE_BY_CLASSIFICATION_URL + id);
  }

  getCasesCounts(status: any, userid: any) {
    return this.http.get<any>(this.GET_CASES_COUNTS_URL + status + '/' + userid);
  }
  updateDetails(data: any, num: any, userid: any, state: any) {
    return this.http.put<any>(this.GET_UPDATE_DETAILS_URL + num + '/' + userid + '/' + state, data)
  }
  getClassifications() {
    return this.http.get<any>(this.GET_CLASSIFICATIONS_URL);
  }
  getContactMethods() {
    return this.http.get<any>(this.GET_CONTACT_METHODS_URL);
  }

  addProduct(data: any) {
    return this.http.post<any>(this.ADD_PRODUCT_URL, data)
  }
  updateproduct(data: any, id: any) {
    return this.http.put<any>(this.UPDATE_PRODUCT_URL + '/' + id, data)
  }
  getCountries() {
    return this.http.get<any>(this.GET_COUNTRIES_URL);
  }
  getCaseDetails(data: any) {
    return this.http.get<any>(this.GET_CASE_DETAILS_URL + '/' + data)
  }
  getCaseFolloeUpVersion(regId: any) {
    return this.http.get<any>(this.GET_CASE_FOLLOWUP_VERSION_URL + '/' + regId)
  }
  getHealthCaseDetails(data: any) {
    return this.http.get<any>(this.GET_HEALTH_CASE_DETAILS_URL + '/' + data)
  }
  getCaseFormFields(roleId: any) {
    return this.http.get<any>(this.GET_CASE_FORM_FIELDS + '/' + roleId)
  }
  updateCaseFormFields(roleId: any, data: any) {
    return this.http.post<any>(this.UPDATE_CASE_FORM_FIELDS + '/' + roleId, data)
  }
  getRegulatoryAuthorities() {
    return this.http.get<any>(this.GET_REGULATORY_AUTHORITIES_URL);
  }
  getSourceDocuments(data: any) {
    return this.http.get<any>(this.GET_SOURCE_DOCUMENTS_URL + '/' + data);
  }
  getCaseDocuments(data: any) {
    return this.http.get<any>(`case/documents/${data}`)
    // return this.http.get<any>(this.GET_SOURCE_DOCUMENTS_URL + '/' + data)
  }
  downloadSourceDoc(data: any) {
    return this.http.get<any>(this.DOWNLOAD_SOURCE_DOC_URL + '/' + data);
  }
  getUsers() {
    return this.http.get<any>(`users/Reviewer`); //NOTUSED
  }
  updateProductDetails(id: any, data: any) {
    return this.http.put<any>(`product/update/${id}`, data) //NOTUSED
  }
  DeleteProductDetails(id: any) {
    return this.http.delete<any>(this.DELETE_PRODUCTS_URL + '/' + id)
  }
  getproducts() {
    return this.http.get<any>(this.GET_PRODUCTS_URL)
  }
  getmodulecasestaus(moduleId: any, roleId: any) {
    return this.http.get<any>(this.GET_MODULE_CASE_STATUS_URL + '/' + moduleId + '/' + roleId)
  }
  postCaseComments(data: any) {
    return this.http.post<any>(this.POST_CASE_COMMENTS_URL, data)
  }
  getCaseComments(id: any) {
    return this.http.get<any>(this.GET_CASE_COMMENTS_URL + '/' + id)
  }
  getProductsByCaseId(id: any) {
    return this.http.get<any>(this.GET_CASE_PRODUCTS_URL + '/' + id)
  }
  sendMail(body: any) {
    return this.http.post<any>(this.SEND_MAIL_URL, body)
  }
  getAcknowledgementTemplate(body: any) {
    return this.http.post<any>(this.GET_ACKNOWLEDGEMENT_TEMPLATE_URL, body)
  }
  getCaseFilterData(moduleName: any) {
    return this.http.get<any>(this.GET_CASE_FILTER_DATA_URL + '/' + moduleName)
  }

  getModulePermissions(roleId: any) {
    return this.http.get<any>(this.GET_MODULE_PERMISSIONS_URL + '/' + roleId)
  }

  getMailTemplates() {
    return this.http.get<any>(this.GET_MAIL_TEMPLATES_URL)
  }

  getDynamicVariables() {
    return this.http.get<any>(this.GET_DYNAMIC_VARAIBLES_URL)
  }

  postMailTemplates(body: any) {
    return this.http.post<any>(this.POST_MAIL_TEMPLATES_URL, body)
  }

  putMailTemplates(id: any, body: any) {
    return this.http.put<any>(`${this.GET_MAIL_TEMPLATES_URL}/${id}`, body)
  }

  changePassword(obj: any) {
    return this.http.post<any>(this.CHANGE_PASSWORD, obj);
  }
  forgotPassword(obj: any) {
    return this.http.put<any>(this.FORGOT_PASSWORD_URL, obj);
  }
  getSecurityQuestions() {
    return this.http.get<any>(this.GET_SECURITY_QUESIONS_URL);
  }
  postReqForPassword(obj: any) {
    return this.http.post(this.REQUEST_PASSWORD, obj);
  }
  submitForAdr(caseid: any, body: any) {
    return this.http.post(this.SUBMIT_FOR_MI_AND_ADR_URL + '/' + caseid, body)
  }
  validatePassword(req: any) {
    return this.http.post(this.VALIDATE_PASSWORD_RESOURCE_URL, req);
  }
  filterdata(items: any, searchTerm: any, list: any) {
    let filteredList: any = [];
    if (searchTerm) {
      let newSearchTerm = !isNaN(searchTerm) ? searchTerm.toString() : searchTerm.toString().toUpperCase();
      let prop;
      return items.filter((item: any) => {
        for (let key in item) {
          if (item[key] != null) {
            if (key != 'id') {
              prop = isNaN(item[key]) ? item[key].toString().toUpperCase() : item[key].toString();
              if (prop.indexOf(newSearchTerm) > -1) {
                filteredList.push(item);
                list = filteredList;
                return list;
              }
            }
          }
        }
      }
      );
    }
    else {
      return items;
    }
  }

  saveAuditTrialsObject(activity: any, remarks: any, typeOfAudit: any, caseId?: any) {
    this. auditObject = {
      activity: this.storageService.getItem('username') + ' ' + activity,
      remarks: remarks,
      typeOfAudit: typeOfAudit,
      ipAddress: this.storageService.getItem('IpAddress'),
      status: 'ACTIVITY_COMPLETED',
      userId: this.storageService.getItem('user_login_id'),
      userName: this.storageService.getItem('username'),
      caseId: caseId
    }
    this.auditService.postAuditTrails(this.auditObject).subscribe((res: any) => {
    }, (error: any) => {
      console.log(error);
    })
    
    
  }
  postAuditTrails(){
    
  }

  //this code is for disable future dates
  disabledDate = (current: Date): boolean => {
    return current && current.valueOf() > Date.now();
  };
  getUsersList(){
    return this.http.get(this.GET_USER_LIST_URL);
  }

  getUsersListData(){
    return this.http.get(this.GET_USERS_LIST_URL);
  }
  
  insertCaseLogs(body: any, roleName: any) {
    return this.http.post<any>(this.INSERT_CASE_LOGS + '/' + roleName, body)
  }

  insertCaseAudits(body: any, caseId: any) {
    return this.http.post<any>(this.INSERT_CASE_AUDITS + `/${caseId}`, body)
  }

  getCaseAuditDetails(body: any) {
    return this.http.post<any>(this.GET_CASE_AUDITS_URL, body)
  }
}